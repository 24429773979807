import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { MacroCalendario } from '../../common-items/macro-calendario';
import { BiglietteriaService } from '../biglietteria.service';

@Component({
  selector: 'app-biglietteria-calendario-scheda',
  templateUrl: './biglietteria-calendario-scheda.component.html',
  styleUrls: ['./biglietteria-calendario-scheda.component.scss']
})
export class BiglietteriaCalendarioSchedaComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  myItem: MacroCalendario;
  titoloScheda: string = "Caricamento...";
  linkBase: string = "/biglietteria/calendario";
  arrayCampi: any[any];

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private route: ActivatedRoute,
    private mainEngineService: MainEngineService,
    public service: BiglietteriaService,
    private notificationsService: NotificationsService,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = this.service.arrayCampiCalendario;

  }

  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);

    this.route.params.subscribe(
      (params) => {
        if (params.id != '0') {
//          this.linkBase += '/lastItem/' + params.id;
          this.service.getMacroCalendario(params.id)
          .subscribe(data => {
            this.myItem = data;
            this.titoloScheda = "Modifica elemento";
            this.formComponentsService.activateLoader(false);
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
          });
        }
        else {
          this.service.getParametri()
          .subscribe(data => {
            delete data.id;
            this.myItem = data;
            this.titoloScheda = "Inserimento elemento";
            this.formComponentsService.activateLoader(false);
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
          });
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      });
  }


  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }


    if (this.myForm.controls['tipologiaInserimento'].value == "1") {
      let value = this.myForm.controls['orariManuali'].value;
      let myArray = value.split(",");
      let regexp = new RegExp(this.formComponentsService.timePatter);
      myArray.forEach( (element) => {
        if (!regexp.test(element)) {
          this.notificationsService.show('error', 'Errore: Il campo "Orari di inizio" non è compliato correttamente. Seguire le istruzioni indicate a fianco della casella');
          return;
        }
      });
    }

    this.loading = true;

    this.service.sendMacroCalendario(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.router.navigate([this.linkBase]);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  formReady(ready: boolean) {
    this.formComponentsService.activateLoader(!ready);
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }
}
