import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnagraficaElencoComponent } from './anagrafica-elenco/anagrafica-elenco.component';
import { AnagraficaFormComponent } from './anagrafica-form/anagrafica-form.component';
import { AnagraficaSchedaComponent } from './anagrafica-scheda/anagrafica-scheda.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { CommonItemsModule } from '../common-items/common-items.module';


@NgModule({
  declarations: [
    AnagraficaElencoComponent,
    AnagraficaFormComponent,
    AnagraficaSchedaComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    CommonItemsModule,
  ],
  exports: [
    AnagraficaFormComponent
  ]
})
export class AnagraficaModule { }
