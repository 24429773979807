import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Prenotazione } from '../prenotazioni/prenotazione';

@Injectable({
  providedIn: 'root'
})
export class ControlloPrenotazioneService {
  apiUrl: string;
  linkBase: string = "/controlloPrenotazioni";
  parametriRicerca: string = null;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
  }

  getByQRCode(securityHash: string): Observable<Prenotazione> {
    return this.http.get<Prenotazione>(this.apiUrl + 'op/getByQRCode?securityHash=' + securityHash)
  }

  getById(idPrenotazione: number): Observable<Prenotazione> {
    return this.http.get<Prenotazione>(this.apiUrl + 'op/getById?id=' + idPrenotazione)
  }

  confermaTutti(idPrenotazione: number) {
    let action = "confermaTutti";
    return this.http.post<any>(this.apiUrl, {action, idPrenotazione})
  }

  confermaParziale(formValues: any) {
    let action = "confermaParziale";
    formValues['action'] = action;
    return this.http.post<any>(this.apiUrl, formValues)
  }

  getCheckedTickets(idPrenotazione: number, securityCode: string): Observable<Prenotazione> {
    return this.http.get<Prenotazione>(this.apiUrl + 'op/getCheckedTickets?idPrenotazione=' + idPrenotazione + '&securityCode=' + securityCode)
  }

  undoCheckedTickets(idPrenotazione: number, securityCode: string): Observable<Prenotazione> {
    let action = "undoCheckedTickets";
    return this.http.post<any>(this.apiUrl, {action, idPrenotazione, securityCode})
  }

  ricercaPrenotazione(formValues: any) {
    let action = "ricercaPrenotazione";
    formValues['action'] = action;
    return this.http.post<any>(this.apiUrl, formValues)
  }
}
