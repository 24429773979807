import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpostazioniComponent } from './impostazioni.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonItemsModule } from '../common-items/common-items.module';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { PrenotazioniModule } from '../prenotazioni/prenotazioni.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    ImpostazioniComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NgbModule,
    CommonItemsModule,
    NgxScannerQrcodeModule,
    PrenotazioniModule,
    FormsModule,
    ReactiveFormsModule

  ]
})
export class ImpostazioniModule { }
