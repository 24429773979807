import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { Prenotazione } from '../../prenotazioni/prenotazione';
import { ControlloPrenotazioneService } from '../controllo-prenotazione.service';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Component({
  selector: 'app-scansiona-qrcode',
  templateUrl: './scansiona-qrcode.component.html',
  styleUrls: ['./scansiona-qrcode.component.scss'],
})
export class ScansionaQrcodeComponent implements OnInit {
  @ViewChild('action', { static: true }) action: NgxScannerQrcodeComponent;
  valid: number = 0;
  idPrenotazione: number = 0;
  myPrenotazione: Prenotazione;
  openVisualizzaIdPrenotazione: number = -1;
  showReader: boolean = false;
  backToParametriRicerca: string = null;

  DEBUG: boolean = false;

  constructor(
    private router: Router,
    private mainEngineService: MainEngineService,
    private formComponentsService: FormInputService,
    private route: ActivatedRoute,
    private service: ControlloPrenotazioneService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
    if (this.service.parametriRicerca != null) {
      this.backToParametriRicerca = this.service.parametriRicerca;
      this.service.parametriRicerca = null;
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params) => {
        if (params.idPrenotazione != undefined) {
          this.loadByid(params.idPrenotazione);
        }
        else {
          this.showReader = true;
          this.action.start();
        }
    });
  }

  loadByid(idPrenotazione) {
    this.idPrenotazione = 0;
    this.formComponentsService.activateLoader(true);
    this.service.getById(idPrenotazione)
    .subscribe(data => {
      this.idPrenotazione = data.id;
      if (data.fail) {
        this.valid = -1;
      }
      else {
        this.myPrenotazione = data;
        this.valid = 1;
      }
      this.formComponentsService.activateLoader(false);
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });
  }

  public onError(e: any): void {
    alert(e);
  }

  onBack() {
    if (!this.DEBUG) {
      this.action.stop();
    }

    if (this.backToParametriRicerca != null) {
      this.router.navigate(['/controlloPrenotazione/ricerca/' + this.backToParametriRicerca]);
    }
    else {
      this.router.navigate(['/controlloPrenotazione']);
    }
  }

  onReadQRCode($event) {
    if (!$event) {
      return;
    }
    this.showReader = false;
    let securityHash = $event;
    if (!this.DEBUG) {
      this.action.stop();
    }
    this.formComponentsService.activateLoader(true);

    this.service.getByQRCode(securityHash)
    .subscribe(data => {
      this.idPrenotazione = data.id;
      if (data.fail) {
        this.valid = -1;
      }
      else {
        this.myPrenotazione = data;
        this.valid = 1;
      }
      this.formComponentsService.activateLoader(false);
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });

  }

  visualizzaPrenotazione() {
    this.openVisualizzaIdPrenotazione = this.idPrenotazione;
  }

  resetOpenIdPrenotazione(reload: boolean = false) {
    this.openVisualizzaIdPrenotazione = -1;
  }

}
