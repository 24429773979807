import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BiglietteriaService } from '../biglietteria.service';

@Component({
  selector: 'app-biglietteria-elenco-tariffe',
  templateUrl: './biglietteria-elenco-tariffe.component.html',
  styleUrls: ['./biglietteria-elenco-tariffe.component.scss']
})
export class BiglietteriaElencoTariffeComponent implements OnInit {
  lastId: number = 0;
  backUrl: string = "/biglietteria";
  options: any[any];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public service: BiglietteriaService
  ) {
    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }
     });
  }

  ngOnInit(): void {
    this.options = {
      linkBase: this.backUrl,
      testataButtons: {
        a0_parametri: {
          type: 'custom',
          testo: 'Parametri',
          colorClass: 'info',
          icon: 'fas fa-tools',
          actionTooltip: 'Gestione parametri',
          ML: true,
          click: this.gestioneParametri.bind(this),
        },
        a1_calendario: {
          type: 'custom',
          testo: 'Calendario',
          colorClass: 'info',
          icon: 'fas fa-calendar-week',
          actionTooltip: 'Gestione fasce calendario',
          ML: true,
          click: this.gestioneCalendario.bind(this),
        },
      }
      ,customButtons: {
        a2_prezzi: {
          type: 'custom',
          testo: 'Gestione',
          colorClass: 'info',
          icon: 'fas fa-cogs',
          actionTooltip: 'Gestione variazione prezzi e disponibilità tariffa',
          ML: true,
          routerLink: 'prezzi',
        }
      }

    }
  }

  gestioneCalendario() {
    this.router.navigate(["/biglietteria/calendario"])
  }

  gestioneParametri() {
    this.router.navigate(["/biglietteria/parametri"])
  }


}
