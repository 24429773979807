import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { ElencoComponent } from 'src/app/components/elenco/elenco.component';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { PrenotazioniService } from '../prenotazioni.service';

@Component({
  selector: 'app-prenotazioni-elenco',
  templateUrl: './prenotazioni-elenco.component.html',
  styleUrls: ['./prenotazioni-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrenotazioniElencoComponent implements OnInit {
  @ViewChild(ElencoComponent) child:ElencoComponent;

  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;
  mostraFiltriDedicati: boolean = false;
  arrayPulsanti: any[any];
  arrayVociOpzioni: any[any];
  arrayCampi: any[any];
  myForm: FormGroup;
  arrayAnagrafica: any[any];
  arrayStatiPrenotazione: any[any];
  openPagamentoIdPrenotazione: number = -1;
  openVisualizzaIdPrenotazione: number = -1;
  filtraPerAnagrafica: number;

  constructor(
    private service: PrenotazioniService,
    private route: ActivatedRoute,
    private formComponentsService: FormInputService,
    private notificationsService: NotificationsService,
    private buttonService: ButtonService,
    private mainEngineService: MainEngineService,
  ) {
    let that = this;

    this.filtraPerAnagrafica = this.service.filtraPerAnagrafica;

    this.arrayPulsanti = {
      'cerca' : {'type' : 'cerca', 'ML' : true},
      'reset' : {'type' : 'reset'},
    };

    forkJoin([
      this.service.getElencoAnagraficaPrenotazioni(),
      this.service.getElencoStatiPrenotazione()
    ])
    .subscribe(
      ([arrayAnagrafica, arrayStatiPrenotazione]) => {
        this.arrayAnagrafica = arrayAnagrafica.items;
        this.arrayStatiPrenotazione = arrayStatiPrenotazione;

        this.arrayCampi = {
          'periodo' : {'id' : 'periodo', 'nome': 'Periodo elementi prenotazione', 'type': 'PER', 'required': false},
          'idPrenotazione' : {'id' : 'idPrenotazione', 'nome': 'Numero prenotazione'},
          'idAnagrafica' : {'id' : 'idAnagrafica', 'nome': 'Anagrafica'},
          'stato' : {'id' : 'stato', 'nome': 'Stato', 'items': this.arrayStatiPrenotazione,},
        };

        this.myForm = this.formComponentsService.createForm(this.arrayCampi);
        this.onReset();
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });

    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'anagrafica', header: 'Anagrafica',    cell: (element: any) => `${element.anagrafica}` },
          { columnDef: 'timestampCreazione', header: 'Data creazione',    cell: (element: any) => `${element.timestampCreazione}` },
          { columnDef: 'timestampModifica', header: 'Ultima modifica',    cell: (element: any) => `${element.timestampModifica}` },
          { columnDef: 'numElementi', header: 'Q.tà',    cell: (element: any) => `${element.numElementi}` },
          { columnDef: 'totale', header: 'Totale', currency: 'EUR',    cell: (element: any) => `${element.totale}` },
          { columnDef: 'pagamento', header: 'Pagamento',    cell: (element: any) => `${element.pagamento}` },
          { columnDef: 'stato', header: 'Stato',    cell: (element: any) => `${element.stato}` },
        ];
        this.options = {
          linkBase: this.myService.linkBase
          ,filtriDedicati: this.showFiltriDedicati.bind(this)
          ,customButtons: {
/*
            a1_opzioni: {
              type: 'custom',
              testo: 'QrCode',
              colorClass: 'light',
              icon: 'mdi mdi-qrcode',
              actionTooltip: 'Visualizza qrcode',
              onClickFunction: this.visualizzaPrenotazione.bind(this),
              thisPointer: that,
              hideText: true,
              ML: true
            },
*/
            a4_visualizza: {
              type: 'custom',
              testo: 'Visual.',
              colorClass: 'info',
              icon: 'mdi mdi-eye',
              actionTooltip: 'Visualizza prenotazione',
              onClickFunction: this.visualizzaPrenotazione.bind(this),
              thisPointer: that,
              hideText: true,
              ML: true
            },
          }
        };

        this.arrayVociOpzioni = {
          test : {
            tipologia: 'route',
            titolo: 'test'
          }
        }
      });
  }

  ngOnInit(): void {
  }

  gestionePagamento(idPrenotazione) {
    this.openPagamentoIdPrenotazione = idPrenotazione;
  }

  annullaPagamento(idPrenotazione: number) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        this.loading = true;
        that.service.annullaPagamento(idPrenotazione)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.reloadElenco();
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenerico(obj, idPrenotazione);
  }


  reloadElenco() {
    this.child.getElenco();
  }

  visualizzaPrenotazione(idPrenotazione) {
    this.openVisualizzaIdPrenotazione = idPrenotazione;
  }

  resetOpenIdPrenotazione(reload: boolean = false) {
    this.openPagamentoIdPrenotazione = -1;
    this.openVisualizzaIdPrenotazione = -1;

    if (reload) {
      this.reloadElenco();
    }
  }

  showFiltriDedicati() {
    this.mostraFiltriDedicati = true;
    this.myForm.patchValue(this.myService.getParametriRicerca());
  }


  onBack() {
    this.mostraFiltriDedicati = false;
  }

  onSearch() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      this.notificationsService.show('warning', '', 'Compilare tutti i campi obbligatori');
      return false;
    }

    this.myService.setParametriRicerca(this.myForm.getRawValue());
    this.onReset();
  }

  onReset() {
    this.myForm.reset();
    this.myForm.patchValue({ 'stato': 'ALL' });
    this.myForm.patchValue({ 'idAnagrafica': this.service.filtraPerAnagrafica });

    if (this.service.filtraPerAnagrafica != 0) {
      this.service.filtraPerAnagrafica = 0;
      this.onSearch();
    }
    else {
      this.service.keepBackupRicerca = false;
      this.filtraPerAnagrafica = 0;
      this.mostraFiltriDedicati = false;
    }
  }
}
