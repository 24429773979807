import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { Anagrafica } from 'src/app/pages/anagrafica/anagrafica';
import { AnagraficaService } from 'src/app/pages/anagrafica/anagrafica.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { PrenotazioniService } from '../../../prenotazioni.service';

@Component({
  selector: 'app-modal-anagrafica',
  templateUrl: './modal-anagrafica.component.html',
  styleUrls: ['./modal-anagrafica.component.scss']
})
export class ModalAnagraficaComponent implements OnInit {
  @ViewChild('modal') modal: any;
  @Input() idPrenotazione: number = 0;
  @Input() openModalId: number = 0;
  @Output() onUpdateAnagrafica = new EventEmitter<number>();
  @Output() onSubmitEmitter = new EventEmitter<number>();
  @Output() onCancelEmitter = new EventEmitter<void>();

  modalReference: any;
  myForm: FormGroup;
  arrayCampi: any[any];
  arrayCampiAggiuntivi: any[any];
  modalTitle: string;
  elemento: Anagrafica
  waitModalContent: boolean = true;
  loading = false;
  arrayAnagrafica: Anagrafica[];

  constructor(
    private modalService: NgbModal,
    private service: AnagraficaService,
    private prenotazioneService: PrenotazioniService,
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private router: Router,
  ) {
    this.arrayCampi = this.service.arrayCampi;

    this.arrayCampiAggiuntivi = {
      'action' : {'id' : 'action', 'defValue' : 'anagrafica', 'type': 'NOSAVE'},
      'idSelAnagrafica' : {'id' : 'idSelAnagrafica', 'nome': 'Scegli per cambiare anagrafica di questa prenotazione', 'type': 'NOSAVE'},
    };

    this.arrayCampi = {...this.arrayCampi, ...this.arrayCampiAggiuntivi};

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit(): void {
  }

  onChangeAnagrafica(idAnagrafica: number = 0) {
    if (idAnagrafica == 0) {
      this.elemento = null;

//      this.myForm.reset(); non posso fare reset del form altrimenti mi resetta anche i campi hidden!!

      for (let item in this.service.arrayCampi) {
        this.myForm.controls[item].reset();
      }
    }
    else {
      this.waitModalContent = true;
      this.service.getItem(idAnagrafica)
      .subscribe(data => {
        this.elemento = data;
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });
    }
  }


  openModal() {
    this.service.getElencoPerSelect()
    .subscribe(data => {
      this.arrayAnagrafica = data;

      this.myForm = this.formComponentsService.createForm(this.arrayCampi);

      let that = this;

      this.modalTitle = "Gestione anagrafica";
      this.modalReference = this.modalService.open(this.modal, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});
      setTimeout(() => {
        that.myForm.patchValue({ 'idSelAnagrafica': that.openModalId });
      }, 200);

    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });
  }

  formReady(ready: boolean) {
    this.waitModalContent = !ready;
  }


  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }
    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (idAnagrafica) => {
        this.onUpdateAnagrafica.emit(idAnagrafica);
        let data = this.myForm.getRawValue();
        data.idAnagrafica = idAnagrafica;
        this.prenotazioneService.aggiornaAnagrafica(data, this.idPrenotazione)
        .pipe(first())
        .subscribe(
          (idPrenotazione) => {
            this.notificationsService.show('not_OpOk');
            this.waitModalContent = true;
            this.loading = false;
            this.modalReference.close();
            this.onSubmitEmitter.emit(idPrenotazione);
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
          }
        );
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.waitModalContent = true;
    this.onCancelEmitter.emit(null);
    this.modalReference.close();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('openModalId' in changes) {
      if (this.openModalId >= 0) {
        this.openModal();
      }
    }
  }
}
