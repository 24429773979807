import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { StatoTariffa } from '../stato-tariffa';
import { Tariffa } from '../tariffa';

@Component({
  selector: 'app-prezzi-tariffa',
  templateUrl: './prezzi-tariffa.component.html',
  styleUrls: ['./prezzi-tariffa.component.scss']
})
export class PrezziTariffaComponent implements OnInit {
  @Input() service: any;

  myForm: FormGroup;
  arrayCampi: any[any];
  arrayTipologiaAttivo: any[any];
  arrayPulsanti: any[any];
  idTariffa: number;
  backUrl = '';
  myItem: Tariffa;
  titoloScheda: string = "Caricamento...";
  columns: any[any];
  displayedColumns: any[];
  dataSource: MatTableDataSource<any>;
  statoTariffa: StatoTariffa[];
  operazione: number = 0;
  mostraPrezzo: boolean = false;
  mostraAttivo: boolean = false;
  mostraPeriodoValidita: boolean = false;
  loading: boolean = false;
  schedaVariazione: boolean = false;
  titoloSchedaVariazione: string = "Caricamento...";

  @ViewChild('navBar') nav;

  constructor(
    private route: ActivatedRoute,
    private mainEngineService: MainEngineService,
    private componentsService: ComponentsService,
    private notificationsService: NotificationsService,
    private buttonService: ButtonService,
    private formComponentsService: FormInputService,
  ) {
    this.arrayTipologiaAttivo = {
      '0': 'Non attivo',
      '1': 'Attivo'
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'idTariffa' : {'id' : 'idTariffa', 'type': 'NUM'},
      'operazione' : {'id' : 'operazione', 'nome': 'Tipologia', 'items': {1: 'Variazione di prezzo per un periodo specifico', 2:'Modifica dello stato di attiva/non attiva per un periodo specifico'}, 'type': 'RAD', 'required': true},
      'prezzo' : {'id' : 'prezzo', 'nome': 'Nuovo prezzo', 'required': false},
      'attivo' : {'id' : 'attivo', 'nome': 'Nuovo stato', 'items': this.arrayTipologiaAttivo, 'type': 'CHK', 'required': false, 'inline': true,},
      'periodoValidita' : {'id' : 'periodoValidita', 'nome': 'Periodo di validità', 'type': 'PER', 'minToday': true, 'required': false},
      'descrizionePrezzo' : {'id' : 'descrizionePrezzo', 'nome': 'Descrizione prezzo', 'type': 'TXA', 'note': 'Descrizione facoltativa relativa alla variazione del prezzo, che sarà mostrata insieme alla denominazione della tariffa ed al prezzo'},
    };

    this.arrayPulsanti = {
      'back' : {'type' : 'back', 'ML' : true},
      'new' : {'type' : 'new', 'ML' : true},
      'edit' : {'type' : 'edit', 'hideText': true, 'ML' : true},
      'delete' : {'type' : 'delete', 'hideText': true, 'ML' : true},
    };
  }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params) => {
        this.idTariffa = params.id;
        this.backUrl = "/biglietteria/lastItem/"+this.idTariffa;

        this.service.getItem(this.idTariffa)
        .subscribe(
          (data) => {
            this.myItem = data;

            this.titoloScheda = "Gestione prezzi '" + this.myItem.denominazione + "'";


            this.myForm = this.formComponentsService.createForm(this.arrayCampi);
            this.myForm.controls.idTariffa.setValue(this.idTariffa);

            this.columns = [
              { columnDef: 'id', header: '#',    cell: (element: any) => `${element.id}` },
              { columnDef: 'variazione', header: 'Variazione',    cell: (element: any) => `${element.variazione}` },
              { columnDef: 'valore', header: 'Nuovo valore',    cell: (element: any) => `${element.valore}` },
              { columnDef: 'descrizionePrezzo', header: 'Descrizione',    cell: (element: any) => `${element.descrizionePrezzo}` },
              { columnDef: 'periodoValidita_dal', header: 'Valido dal',    cell: (element: any) => `${element.periodoValidita_dal}` },
              { columnDef: 'periodoValidita_al', header: 'Valido al',    cell: (element: any) => `${element.periodoValidita_al}` },
            ];

            this.displayedColumns = this.columns.map(c => c.columnDef);
            this.displayedColumns.push('actions');


            this.getStatoTariffa();
          },
          (error) => {
            this.mainEngineService.errorHandler(error);
          });
      });
  }

  getStatoTariffa(showLoader = true) {
    if (showLoader) {
      this.formComponentsService.activateLoader(true);
    }

    this.service.getStatoTariffa(this.idTariffa)
    .subscribe(
      data => {
        this.statoTariffa = data;

        if (showLoader) {
          this.formComponentsService.activateLoader(false);
        }
      },
      (error) => {
        this.formComponentsService.activateLoader(false);
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  getElencoVariazioni(showLoader = true) {
    if (showLoader) {
      this.formComponentsService.activateLoader(true);
    }

    this.service.getElencoVariazioni(this.idTariffa)
    .subscribe(
      data => {
        this.dataSource = new MatTableDataSource(data);
        this.componentsService.tableFitHeight();

        if (showLoader) {
          this.formComponentsService.activateLoader(false);
        }
      },
      (error) => {
        this.formComponentsService.activateLoader(false);
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onTabChanged(event) {
    switch (event.nextId) {
      case 1:
        this.getStatoTariffa();
        break;

      case 2:
        this.getElencoVariazioni();
        break;


      case 3:
          this.onClickOperazione(true);
        break;
    }
  }

  onClickOperazione(reset: boolean = false) {
    if (reset) {
      this.operazione = 0;
      this.myForm.patchValue({'operazione': '0'});
    }
    else {
      this.operazione = this.myForm.getRawValue().operazione;
    }
/*
1: prezzo + periodoValidita
2: attivo + periodoValidita
*/

    // rimuovo prezzo
    this.myForm.patchValue({'prezzo': ''});
    this.myForm.get('prezzo').clearValidators();
    this.mostraPrezzo = false;
    // rimuovo validoDal
    this.myForm.patchValue({'attivo': ''});
    this.myForm.get('attivo').clearValidators();
    this.mostraAttivo = false;
    // rimuovo periodoValidita
    this.myForm.patchValue({'periodoValidita_dal': ''});
    this.myForm.patchValue({'periodoValidita_al': ''});
    this.mostraPeriodoValidita = false;

    if (this.operazione != 0) {
// abilito periodoValidita
      this.mostraPeriodoValidita = true;
    }

    if (this.operazione == 1) {
      this.myForm.get('prezzo').setValidators([Validators.required]);
      this.mostraPrezzo = true;
    }
    else if (this.operazione == 2) {
      this.myForm.get('attivo').setValidators([Validators.required]);
      this.mostraAttivo = true;
    }

    this.myForm.get('prezzo').updateValueAndValidity();
    this.myForm.get('attivo').updateValueAndValidity();
  }

  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      this.notificationsService.show('warning', '', 'Alcuni campi non sono stati compilati correttamente');
      return false;
    }

    this.loading = true;

    this.service.sendVariazione(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.myForm.reset();
        this.myForm.controls.idTariffa.setValue(this.idTariffa);
        this.onClickOperazione(true);
        this.loading = false;

        this.mostraSchedaVariazione(false);
        this.getElencoVariazioni(true);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );

  }

  mostraSchedaVariazione(mostra) {
    this.schedaVariazione = mostra;
  }


  editItem(idItem) {
    if (!idItem) {
      this.myForm.patchValue({'id': '0'});
      this.titoloSchedaVariazione = "Inserimento nuova variazione";
      this.mostraSchedaVariazione(true);
    }
    else {
      this.formComponentsService.activateLoader(true);
      this.service.getVariazione(idItem)
      .subscribe(
        (data) => {
          this.myForm.patchValue({'operazione' : data.operazione});
          this.onClickOperazione();
          this.myForm.patchValue(data);
          this.titoloSchedaVariazione = "Modifica variazione";
          this.formComponentsService.activateLoader(false);
          this.mostraSchedaVariazione(true);
        },
        (error) => {
          this.mainEngineService.errorHandler(error);
        });
    }
  }

  onDelete(id) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        this.loading = true;
        that.service.deleteVariazione(arg)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.getElencoVariazioni(true);
          },
          (error) => {
            this.loading = false;
            that.mainEngineService.errorHandler(error);
          }
        );
      }
    };

    this.buttonService.confermaElimina(obj, id);
  }

  onBack() {
    this.componentsService.onBack(this.backUrl);
  }

  onCancel() {
    this.onClickOperazione(false);
    this.mostraSchedaVariazione(false);
  }
}
