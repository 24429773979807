import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-check-fail',
  templateUrl: './check-fail.component.html',
  styleUrls: ['./check-fail.component.scss']
})
export class CheckFailComponent implements OnInit {
  @Input() idPrenotazione: number;
  @Output() openModalDettaglioEmitter = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  openModalDettaglio() {
    this.openModalDettaglioEmitter.emit(1);
  }

}
