import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Anagrafica } from 'src/app/pages/anagrafica/anagrafica';
import { AnagraficaService } from 'src/app/pages/anagrafica/anagrafica.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Prenotazione } from '../../../prenotazione';

@Component({
  selector: 'app-tab-anagrafica',
  templateUrl: './tab-anagrafica.component.html',
  styleUrls: ['./tab-anagrafica.component.scss']
})
export class TabAnagraficaComponent implements OnInit {
  @Input() prenotazione: Prenotazione;
  @Output() reloadPrenotazione = new EventEmitter<number>();

  myAnagrafica: Anagrafica;
  arrayPulsanti: any[any];
  openModalId: number = -1;
  idAnagrafica: number = 0;

  constructor(
    private service: AnagraficaService,
    private mainEngineService: MainEngineService,
  ) {
    this.arrayPulsanti = {
      'modifica' : {'type' : 'edit'},
    };
   }

  ngOnInit(): void {
    this.idAnagrafica = (this.prenotazione && this.prenotazione.idAnagrafica) ? this.prenotazione.idAnagrafica : 0;
    if (!this.prenotazione || !this.prenotazione.id) {
      this.prenotazione = new(Prenotazione);
      this.prenotazione.id = 0;
    }
    if (this.idAnagrafica != 0) {
      this.service.getItem(this.idAnagrafica)
      .subscribe(data => {
        this.myAnagrafica = data;
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });
    }
  }

  updateAnagrafica(idAnagrafica) {
    this.prenotazione.idAnagrafica = parseInt(idAnagrafica);
    this.ngOnInit();
  }

  modalForm() {
    this.openModalId = this.idAnagrafica;
  }

  onSubmit(idPrenotazione: number = 0) {
    this.onCancel();
    this.reloadPrenotazione.emit(idPrenotazione);
  }

  onCancel() {
    this.openModalId = -1;
  }
}
