import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ElementiService } from '../elementi.service';
import { ElementoPrenotazione } from '../elemento-prenotazione';

@Component({
  selector: 'app-modal-rimuovi-elemento',
  templateUrl: './modal-rimuovi-elemento.component.html',
  styleUrls: ['./modal-rimuovi-elemento.component.scss']
})
export class ModalRimuoviElementoComponent implements OnInit {
  @ViewChild('modal') modal: any;
  @Input() idPrenotazione: number = 0;
  @Input() openModalElemento: number = 0;
  @Input() elementiPrenotazione: ElementoPrenotazione[];
  @Output() onSubmitEmitter = new EventEmitter<number>();
  @Output() onCancelEmitter = new EventEmitter<void>();
/* openModalElemento sarà sempre 0 in quanto per l'elimina ci sarà un elenco di biglietti acquistati tra cui selezionare
*/

  arrayCampi: any[any];
  myForm: FormGroup;
  loading = false;
  waitModalContent: boolean = true;
  modalReference: any;
  arrayPulsanti: any[any];
  arraySelezionati = new Array<boolean>();
  arraySelezionatiAnnulla = new Array<boolean>();
  arraySelezionatiConferma = new Array<boolean>();

  constructor(
    private formComponentsService: FormInputService,
    private service: ElementiService,
    private notificationsService: NotificationsService,
    private modalService: NgbModal,
    private mainEngineService: MainEngineService,
  ) {
    this.arrayCampi = {
      'idPrenotazione' : {'id' : 'idPrenotazione', 'type': 'NUM'},
      'action' : {'id' : 'action'},
      'itemsToRemove' : {'id' : 'itemsToRemove'},
      'itemsToUndo' : {'id' : 'itemsToUndo'},
      'itemsToConfirm' : {'id' : 'itemsToConfirm'},
    };

    this.arrayPulsanti = {
      'annulla' : {'type' : 'custom', 'testo' : 'Annulla', 'colorClass' : 'warning', 'icon': 'fas fa-undo', 'actionTooltip' : 'Annulla ingresso', 'ML' : false, 'mainClass' : 'btn-xs'},
      'elimina' : {'type' : 'custom', 'testo' : 'Elimina', 'colorClass' : 'danger', 'icon': 'fas fa-trash', 'actionTooltip' : 'Elimina elemento', 'ML' : false, 'mainClass' : 'btn-xs'},
      'conferma' : {'type' : 'custom', 'testo' : 'Conferma', 'colorClass' : 'success', 'icon': 'fas fa-check', 'actionTooltip' : 'Conferma ingresso', 'ML' : false, 'mainClass' : 'btn-xs'},
      'ripristina' : {'type' : 'custom', 'testo' : 'Ripristina', 'colorClass' : 'secondary', 'icon': 'fas fa-trash-restore', 'actionTooltip' : 'Ripristina elemento', 'ML' : false, 'mainClass' : 'btn-xs'},
    };
  }

  ngOnInit(): void {
  }

  openModal() {
    this.modalReference = this.modalService.open(this.modal, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.myForm.patchValue({ 'idPrenotazione': this.idPrenotazione });
    this.myForm.patchValue({ 'action': 'gestisciElementi' });

    for (let key in this.elementiPrenotazione) {
      for (let item of this.elementiPrenotazione[key].arrayId) {
        this.arraySelezionati[item.id] = false;
      }
      for (let item of this.elementiPrenotazione[key].arrayIdUtilizzati) {
        this.arraySelezionatiAnnulla[item.id] = false;
      }
      for (let item of this.elementiPrenotazione[key].arrayIdAnnullati) {
        this.arraySelezionati[item.id] = true;
      }
    }
    this.waitModalContent = false;
/*
    this.prenotazioniService.getTipologieElementi()
    .subscribe(data => {
      this.arrayTipologieElementi = data.items;
      if (this.openModalElemento == 0) {
        this.modalTitle = "Aggiunta elemento";
        this.myForm.patchValue({ 'tipologiaElemento': 'B' });
        this.myForm.patchValue({ 'idElemento': 0 });
        this.waitModalContent = false;
      }
      else {

      }
      this.myForm.get('orario').clearValidators();
      this.myForm.get('orario').updateValueAndValidity();
      this.myFormIniziale = this.myForm;
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });
    */
  }


  selezionaElemento(idElemento, elimina) {
    if (elimina) {
      $("#riga_" + idElemento).addClass("elementoEliminato");
    }
    else {
      $("#riga_" + idElemento).removeClass("elementoEliminato");
    }
    this.arraySelezionati[idElemento] = elimina;
    this.myForm.markAsDirty();
  }


  selezionaElementoAnnulla(idElemento, annulla) {
    if (annulla) {
      $("#riga_" + idElemento).removeClass("elementoUtilizzato");
    }
    else {
      $("#riga_" + idElemento).addClass("elementoUtilizzato");
    }
    this.arraySelezionatiAnnulla[idElemento] = annulla;
    this.myForm.markAsDirty();
  }

  selezionaElementoConferma(idElemento, conferma) {
    if (conferma) {
      $("#riga_" + idElemento).addClass("elementoUtilizzato");
    }
    else {
      $("#riga_" + idElemento).removeClass("elementoUtilizzato");
    }
    this.arraySelezionatiConferma[idElemento] = conferma;
    this.myForm.markAsDirty();
  }



  onSubmit() {
    this.myForm.patchValue({ 'itemsToRemove': this.arraySelezionati ,  'itemsToUndo': this.arraySelezionatiAnnulla ,  'itemsToConfirm': this.arraySelezionatiConferma });

    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.gestisciElementiPrenotazione(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      () => {
        this.notificationsService.show('not_OpOk');
        this.waitModalContent = true;
        this.loading = false;
        this.modalReference.close();
        this.onSubmitEmitter.emit(this.idPrenotazione);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.waitModalContent = true;
    this.onCancelEmitter.emit(null);
    this.modalReference.close();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('openModalElemento' in changes) {
      if (this.openModalElemento >= 0) {
        this.openModal();
      }
    }
  }
}
