import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { ControlloPrenotazioneService } from '../controllo-prenotazione.service';
import { first } from 'rxjs/operators';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';

@Component({
  selector: 'app-ricerca',
  templateUrl: './ricerca.component.html',
  styleUrls: ['./ricerca.component.scss']
})
export class RicercaComponent implements OnInit {
  myForm: FormGroup;
  arrayCampi: any[any];
  loading: boolean = false;
  items: any[] = null;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private service: ControlloPrenotazioneService
  ) {
    this.arrayCampi = {
      'key' : {'id' : 'key', 'nome' : 'Valore da ricercare', 'required': true},
    };
  }

  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.route.params.subscribe(
      (params) => {
        if (params.parametriRicerca != undefined) {
          this.myForm.patchValue({'key': params.parametriRicerca});
          this.ricerca();
        }
    });
  }

  onBack() {
    this.router.navigate(['/controlloPrenotazione']);
  }

  ricerca() {
    this.items = null;
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    let key = this.myForm.controls['key'].value;
    if ((key.length < 3) && (key != parseInt(key))) {
      this.notificationsService.show('warning', '', 'La chiave di ricerca deve essere un numero intero oppure deve contenere almeno 3 caratteri');
      return;
    }

    this.service.parametriRicerca = key;
    this.loading = true;

    this.service.ricercaPrenotazione(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (data) => {
        this.items = data.items;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  selezionaPrenotazione(idPrenotazione) {
    this.router.navigate(['/controlloPrenotazione/qrcode/' + idPrenotazione + '/']);
  }
}
