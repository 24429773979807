import { Routes } from '@angular/router';
import { Page404Component } from 'src/app/components/page404/page404.component';
import { AnagraficaElencoComponent } from 'src/app/pages/anagrafica/anagrafica-elenco/anagrafica-elenco.component';
import { AnagraficaSchedaComponent } from 'src/app/pages/anagrafica/anagrafica-scheda/anagrafica-scheda.component';
import { BiglietteriaCalendarioSchedaComponent } from 'src/app/pages/biglietteria/biglietteria-calendario-scheda/biglietteria-calendario-scheda.component';
import { BiglietteriaCalendarioComponent } from 'src/app/pages/biglietteria/biglietteria-calendario/biglietteria-calendario.component';
import { BiglietteriaElencoTariffeComponent } from 'src/app/pages/biglietteria/biglietteria-elenco-tariffe/biglietteria-elenco-tariffe.component';
import { BiglietteriaParametriComponent } from 'src/app/pages/biglietteria/biglietteria-parametri/biglietteria-parametri.component';
import { BiglietteriaPrezziTariffaComponent } from 'src/app/pages/biglietteria/biglietteria-prezzi-tariffa/biglietteria-prezzi-tariffa.component';
import { BiglietteriaSchedaTariffaComponent } from 'src/app/pages/biglietteria/biglietteria-scheda-tariffa/biglietteria-scheda-tariffa.component';
import { MainControlloComponent } from 'src/app/pages/controllo-prenotazione/main-controllo/main-controllo.component';
import { OpDoneComponent } from 'src/app/pages/controllo-prenotazione/op-done/op-done.component';
import { RicercaComponent } from 'src/app/pages/controllo-prenotazione/ricerca/ricerca.component';
import { ScansionaQrcodeComponent } from 'src/app/pages/controllo-prenotazione/scansiona-qrcode/scansiona-qrcode.component';
import { HomepageComponent } from 'src/app/pages/homepage/homepage.component';
import { ImpostazioniComponent } from 'src/app/pages/impostazioni/impostazioni.component';
import { MetodiPagamentoElencoComponent } from 'src/app/pages/metodi-pagamento/metodi-pagamento-elenco/metodi-pagamento-elenco.component';
import { MetodiPagamentoSchedaComponent } from 'src/app/pages/metodi-pagamento/metodi-pagamento-scheda/metodi-pagamento-scheda.component';
import { PrenotazioniElencoComponent } from 'src/app/pages/prenotazioni/prenotazioni-elenco/prenotazioni-elenco.component';
import { PrenotazioniSchedaComponent } from 'src/app/pages/prenotazioni/prenotazioni-scheda/prenotazioni-scheda.component';


export const MainLayoutRoutes: Routes = [
  {
    path: '',
//    component: HomepageComponent
      component: PrenotazioniElencoComponent
  },
  {
    path: 'home',
//    component: HomepageComponent
    component: PrenotazioniElencoComponent
  },
  {
    path: '404',
    component: Page404Component
  },
  {
    path: 'biglietteria',
    component: BiglietteriaElencoTariffeComponent
  },
  {
    path: 'biglietteria/calendario',
    component: BiglietteriaCalendarioComponent
  },
  {
    path: 'biglietteria/calendario/form/:id',
    component: BiglietteriaCalendarioSchedaComponent
  },
  {
    path: 'biglietteria/parametri',
    component: BiglietteriaParametriComponent
  },
  {
    path: 'biglietteria/:id/prezzi',
    component: BiglietteriaPrezziTariffaComponent
  },
  {
    path: 'biglietteria/:id',
    component: BiglietteriaSchedaTariffaComponent
  },
  {
    path: 'biglietteria/lastItem/:id',
    component: BiglietteriaElencoTariffeComponent
  },
  {
    path: 'anagrafica/lastItem/:id',
    component: AnagraficaElencoComponent
  },
  {
    path: 'anagrafica',
    component: AnagraficaElencoComponent
  },
  {
    path: 'anagrafica/:id',
    component: AnagraficaSchedaComponent
  },
  {
    path: 'metodiPagamento',
    component: MetodiPagamentoElencoComponent
  },
  {
    path: 'metodiPagamento/lastItem/:id',
    component: MetodiPagamentoElencoComponent
  },
  {
    path: 'metodiPagamento/:id',
    component: MetodiPagamentoSchedaComponent
  },
  {
    path: 'prenotazioni',
    component: PrenotazioniElencoComponent
  },
  {
    path: 'prenotazioni/lastItem/:id',
    component: PrenotazioniElencoComponent
  },
  {
    path: 'prenotazioni/:id',
    component: PrenotazioniSchedaComponent
  },
  {
    path: 'controlloPrenotazione',
    component: MainControlloComponent
  },
  {
    path: 'controlloPrenotazione/qrcode/:idPrenotazione',
    component: ScansionaQrcodeComponent
  },
  {
    path: 'controlloPrenotazione/qrcode',
    component: ScansionaQrcodeComponent
  },
  {
    path: 'controlloPrenotazione/ricerca/:parametriRicerca',
    component: RicercaComponent
  },
  {
    path: 'controlloPrenotazione/ricerca',
    component: RicercaComponent
  },
  {
    path: 'controlloPrenotazione/opDone/:idPrenotazione/:securityCode',
    component: OpDoneComponent
  },
  {
    path: 'impostazioni',
    component: ImpostazioniComponent
  },
];

