import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { Prenotazione } from '../../prenotazioni/prenotazione';
import Swal from 'sweetalert2';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { ControlloPrenotazioneService } from '../controllo-prenotazione.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { FormGroup } from '@angular/forms';
import { NotificationsService } from 'src/app/components/service/notification.service';

@Component({
  selector: 'app-check-success',
  templateUrl: './check-success.component.html',
  styleUrls: ['./check-success.component.scss']
})
export class CheckSuccessComponent implements OnInit {
  @Input() myPrenotazione: Prenotazione;
  @Output() openModalDettaglioEmitter = new EventEmitter();
  @Output() onReloadEmitter = new EventEmitter();
  modalitaAccessoParziale = false;
  ticketForm: FormGroup;
  arrayCampiBiglietti: any[any] = {};

  constructor(
    private formComponentsService: FormInputService,
    private buttonService: ButtonService,
    private service: ControlloPrenotazioneService,
    private router: Router,
    private notificationsService: NotificationsService,
    private mainEngineService: MainEngineService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    for (let i in this.myPrenotazione.items) {
      this.arrayCampiBiglietti['ticket_' + this.myPrenotazione.items[i].idT] = {'id': 'ticket_' + this.myPrenotazione.items[i].idT, 'type': 'SEL'};
    }
    this.arrayCampiBiglietti['idPrenotazione'] = {'id': 'idPrenotazione', 'defValue': this.myPrenotazione.id};
  }

  openModalDettaglio() {
    this.openModalDettaglioEmitter.emit(1);
  }


  refresh() {
    this.annullaAccessoParziale();
    this.onReloadEmitter.emit(null);
  }

  confermaTutti() {
    const that = this;
    const obj = {
      onSuccess(arg) {
        that.formComponentsService.activateLoader(true);
        that.service.confermaTutti(arg)
        .pipe(first())
        .subscribe(
          (response) => {
            that.router.navigate(["/controlloPrenotazione/opDone/"+arg+"/"+response.securityCode]);
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenericoSmart(obj, this.myPrenotazione.id);
  }

  accessoParziale() {
    this.modalitaAccessoParziale = true;
    this.ticketForm = this.formComponentsService.createForm(this.arrayCampiBiglietti);
  }

  annullaAccessoParziale() {
    this.modalitaAccessoParziale = false;
  }


  confermaAccessoParziale() {
    const that = this;
    const obj = {
      onSuccess(arg) {
        that.formComponentsService.activateLoader(true);
        that.service.confermaParziale(arg)
        .pipe(first())
        .subscribe(
          (response) => {
            that.router.navigate(["/controlloPrenotazione/opDone/"+arg.idPrenotazione+"/"+response.securityCode]);
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
            that.refresh();
          }
        );

      }
    };

    let pass = false;
    for (let key in this.ticketForm.value) {
      if (key.indexOf("ticket_") != 0) {
        continue;
      }
      if ((this.ticketForm.value[key] != '') && (parseInt(this.ticketForm.value[key]) > 0)) {
        pass = true;
        break;
      }
    }

    if (!pass) {
      this.notificationsService.show('error', 'Attenzione: non è stato selezionato nessun biglietto di ingresso');
      return;
    }

    this.buttonService.confermaGenericoSmart(obj, this.ticketForm.value);
  }

}
