import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetodiPagamentoElencoComponent } from './metodi-pagamento-elenco/metodi-pagamento-elenco.component';
import { MetodiPagamentoSchedaComponent } from './metodi-pagamento-scheda/metodi-pagamento-scheda.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { CommonItemsModule } from '../common-items/common-items.module';



@NgModule({
  declarations: [
    MetodiPagamentoElencoComponent,
    MetodiPagamentoSchedaComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    CommonItemsModule,
  ]
})
export class MetodiPagamentoModule { }
