import { Component, OnInit } from '@angular/core';
import { BiglietteriaService } from '../biglietteria.service';


@Component({
  selector: 'app-biglietteria-prezzi-tariffa',
  templateUrl: './biglietteria-prezzi-tariffa.component.html',
  styleUrls: ['./biglietteria-prezzi-tariffa.component.scss']
})
export class BiglietteriaPrezziTariffaComponent implements OnInit {

  constructor(
    public service: BiglietteriaService
  ) {
  }

  ngOnInit(): void {

  }

}
