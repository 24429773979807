import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Elemento } from './elemento';
import { ElementoPrenotazione } from './elemento-prenotazione';


@Injectable({
  providedIn: 'root'
})
export class ElementiService {
  apiUrl: string;
  linkBase: string = "/prenotazioni";

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
  }

  getElencoElementiPrenotazione(idPrenotazione: number): Observable<ElementoPrenotazione[]> {
    return this.http.get<ElementoPrenotazione[]>(this.apiUrl + 'op/elementiPrenotazione?idPrenotazione=' + idPrenotazione)
  }

  getItem(id: number): Observable<Elemento> {
    return this.http.get<Elemento>(this.apiUrl + 'op/elemento?id=' + id)
  }

  gestisciElementiPrenotazione(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  sendElementoPrenotazione(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }
}
