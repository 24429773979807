import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Prenotazione } from './prenotazione';

@Injectable({
  providedIn: 'root'
})
export class PrenotazioniService {
  apiUrl: string;
  linkBase: string = "/prenotazioni";
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;
  arrayRicerca: any[any] = [];
  arrayBackupRicerca: any[any] = [];
  keepBackupRicerca: boolean = false;
  fromRicerca: boolean = false;
  filtraPerAnagrafica: number = 0;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');
  }

  setParametriRicerca(formValue: any[]) {
    this.arrayRicerca = formValue;
    this.fromRicerca = true;
    this.keepBackupRicerca = false;
  }

  getParametriRicerca() {
    return this.arrayBackupRicerca;
  }

  getElenco(): Observable<Prenotazione[]> {
    let dummy = "";
    if (this.keepBackupRicerca) {
      dummy = this.arrayBackupRicerca;
    }
    else {
      if (this.fromRicerca) {
        dummy = this.arrayRicerca;
        this.fromRicerca = false;
        this.keepBackupRicerca = true;
        this.arrayBackupRicerca = this.arrayRicerca;
        this.arrayRicerca = [];
      }
      else {
        dummy = null;
        this.arrayBackupRicerca = [];
      }
    }
    return this.http.patch<Prenotazione[]>(this.apiUrl, dummy)
  }

  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + 'op/opzioni')
  }


  getItem(id: number): Observable<Prenotazione> {
    return this.http.get<Prenotazione>(this.apiUrl + id)
  }


  deleteItem(id: number) {
    return this.http.delete<Prenotazione>(this.apiUrl + id)
  }

  sendPagamento(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  annullaPagamento(id: number) {
    let action = "annullaPagamento";
    return this.http.post<any>(this.apiUrl ,{id, action})
  }

  getElencoTariffeElemento(formValues): Observable<any> {
    let action = "elencoTariffeElemento";
    return this.http.post<any>(this.apiUrl , {formValues, action});
  }

  getTipologieElementi(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/tipologieElementi');
  }

  getElencoElementi(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elencoElementi');
  }

  getElencoAnagraficaPrenotazioni(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elencoAnagraficaPrenotazioni');
  }

  getElencoStatiPrenotazione(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elencoStatiPrenotazione');
  }

  getElencoMetodiPagamento(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elencoMetodiPagamento');
  }

  getPagamento(idPrenotazione: number) {
    return this.http.get<any>(this.apiUrl + 'op/statoPagamento?idPrenotazione=' + idPrenotazione);
  }

  aggiornaAnagrafica(data, id) {
    let action = "aggiornaAnagrafica";
    return this.http.post<any>(this.apiUrl , {data, id, action});
  }

  getRiassuntoPrenotazione(idPrenotazione: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/riassuntoPrenotazione?idPrenotazione=' + idPrenotazione);
  }

  clickItemButton(button, item) {
    button.value.onClickFunction(item.id);
  }

  confermaPrenotazione(id) {
    let action = "confermaPrenotazione";
    return this.http.post<any>(this.apiUrl , {id, action});
  }

  annullaPrenotazione(id, annulla) {
    let action = "annullaPrenotazione";
    return this.http.post<any>(this.apiUrl , {id, annulla, action});
  }

  reinviaEmail(id) {
    let action = "reinviaEmail";
    return this.http.post<any>(this.apiUrl , {id, action});
  }
}
