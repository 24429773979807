import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BiglietteriaElencoTariffeComponent } from './biglietteria-elenco-tariffe/biglietteria-elenco-tariffe.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { CommonItemsModule } from '../common-items/common-items.module';
import { BiglietteriaSchedaTariffaComponent } from './biglietteria-scheda-tariffa/biglietteria-scheda-tariffa.component';
import { BiglietteriaCalendarioComponent } from './biglietteria-calendario/biglietteria-calendario.component';
import { BiglietteriaParametriComponent } from './biglietteria-parametri/biglietteria-parametri.component';
import { BiglietteriaPrezziTariffaComponent } from './biglietteria-prezzi-tariffa/biglietteria-prezzi-tariffa.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableModule } from '@angular/material/table';
import { BiglietteriaCalendarioSchedaComponent } from './biglietteria-calendario-scheda/biglietteria-calendario-scheda.component';


@NgModule({
  declarations: [
    BiglietteriaElencoTariffeComponent,
    BiglietteriaSchedaTariffaComponent,
    BiglietteriaCalendarioComponent,
    BiglietteriaParametriComponent,
    BiglietteriaPrezziTariffaComponent,
    BiglietteriaCalendarioSchedaComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    CommonItemsModule,
    NgbModule,
    MatTableModule,
  ]
})
export class BiglietteriaModule { }
