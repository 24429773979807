import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Parametri } from '../../common-items/parametri';
import { BiglietteriaService } from '../biglietteria.service';

@Component({
  selector: 'app-biglietteria-parametri',
  templateUrl: './biglietteria-parametri.component.html',
  styleUrls: ['./biglietteria-parametri.component.scss']
})
export class BiglietteriaParametriComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  myItem: Parametri;
  titoloScheda: string = "Caricamento...";
  linkBase: string = "/biglietteria";
  arrayCampi: any[any];

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    public service: BiglietteriaService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService
  ) {
    this.arrayCampi = this.service.arrayCampiParametri;
  }

  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);

    this.service.getParametri()
    .subscribe(data => {
      this.myItem = data;
      this.titoloScheda = "Modifica parametri biglietteria";
      this.formComponentsService.activateLoader(false);
    },
    (error) => {
      this.loading = false;
      this.mainEngineService.errorHandler(error);
    });
  }


  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendParametri(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.router.navigate([this.linkBase]);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  formReady(ready: boolean) {
    this.formComponentsService.activateLoader(!ready);
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }
}
