import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Prenotazione } from '../../../prenotazione';
import { ElementiService } from '../elementi.service';
import { ElementoPrenotazione } from '../elemento-prenotazione';

@Component({
  selector: 'app-tab-elenco-elementi',
  templateUrl: './tab-elenco-elementi.component.html',
  styleUrls: ['./tab-elenco-elementi.component.scss']
})
export class TabElencoElementiComponent implements OnInit {
  @Input() prenotazione: Prenotazione;
  @Output() reloadPrenotazione = new EventEmitter<number>();

  openModalAggiungi: number = -1;
  openModalRimuovi: number = -1;
  elementiPrenotazione: ElementoPrenotazione[];
  arrayPulsanti: any[any];

  constructor(
    private service: ElementiService,
    private notificationsService: NotificationsService,
    private mainEngineService: MainEngineService,
    private buttonService: ButtonService
  ) {
    this.arrayPulsanti = {
      'aggiungiElemento' : {'type' : 'custom', 'testo' : 'Aggiungi elementi', 'colorClass' : 'primary', 'icon': 'fas fa-cart-plus', 'actionTooltip' : 'Aggiungi elementi alla prenotazione', 'ML' : false, 'mainClass' : 'btn-md'},
      'rimuoviElemento' : {'type' : 'delete', 'testo' : 'Gestisci elementi', 'actionTooltip' : 'Rimuovi elementi dalla prenotazione o ripristina elementi eliminati', 'ML' : true, 'mainClass' : 'btn-md'},
    };
  }

  ngOnInit(): void {
    if (this.prenotazione && this.prenotazione.id) {
      this.service.getElencoElementiPrenotazione(this.prenotazione.id)
      .subscribe(data => {
        this.elementiPrenotazione = data;
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });
    }
  }


  modalForm(idElemento: number = 0) {
    this.openModalAggiungi = idElemento;
  }

  modalRimuovi() {
    this.openModalRimuovi = 1;
  }

  onSubmit(idPrenotazione: number) {
    this.onCancel();
    this.ngOnInit();
    this.reloadPrenotazione.emit(idPrenotazione);
  }

  onCancel() {
    this.openModalAggiungi = -1;
    this.openModalRimuovi = -1;
  }

}
