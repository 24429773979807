import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ComponentsService } from 'src/app/components/component.service';
import { CalendarOptions, EventClickArg, EventApi, FullCalendarComponent } from '@fullcalendar/angular';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { LoaderService } from 'src/app/service/loader.service';
import { NotificationsService } from 'src/app/components/service/notification.service';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarioComponent implements OnInit {
  @Input() service: any;
  @Input() tipologia: string;
  @Input() idPadre: number = 0;

  backUrl: string;
  titoloScheda: string;
  newUrl: string;
  calendarOptions: CalendarOptions;

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  constructor(
    private componentsService: ComponentsService,
    private loader: LoaderService,
    private notificationsService: NotificationsService,
    private mainEngineService: MainEngineService
  ) {

  }

  ngOnInit(): void {
    this.backUrl = "/biglietteria";
    this.titoloScheda = "Gestione calendario biglietteria";
    this.newUrl = "/biglietteria/calendario/form/0";

    this.calendarOptions = {
      headerToolbar: {
        left: 'dayGridMonth,timeGridWeek,timeGridDay',
        center: 'title',
        right: 'prevYear,prev,next,nextYear'
      },
      views: {
        timeGridDay: {
          buttonText: 'Giorno'
        },
        timeGridWeek: {
          buttonText: 'Settimana'
        },
        dayGridMonth: {
          buttonText: 'Mese'
        }
      },

      timeZone: 'Europe/Rome',
      locale: 'it',
      weekNumbers: true,
      weekNumberCalculation: 'ISO',
      nowIndicator: true,
      slotLabelFormat: {hour: '2-digit', minute: '2-digit'},
      allDaySlot: false,

      initialView: "timeGridWeek",
      themeSystem: "bootstrap",
      weekends: true,
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      handleWindowResize: true,
      height: 'auto',

      eventDidMount: function(info) {
        let stringa: string = info.el.innerHTML;
        stringa = stringa.split('{{').join('<');
        stringa = stringa.split('}}').join('>');
        info.el.innerHTML = stringa;
        $('td.fc-list-event-time').remove();
      }


    };
    this.getParametri();
  }

  getParametri(): void {
    this.service.getParametriCalendario()
    .subscribe(data => {
      if (data.empty) {
        $(".calendarBody").hide();
        $(".noCalendar").show();
        this.loader.spin.next(false);
      }
      else {
        $(".noCalendar").hide();
        $(".calendarBody").show();
        this.calendarOptions.slotMinTime = data.orarioMin;
        this.calendarOptions.slotMaxTime = data.orarioMax;
        this.calendarOptions.slotDuration = '00:15:00';
        this.calendarOptions.slotLabelInterval = '00:15:00';

        this.calendarOptions.events = this.mainEngineService.baseUrl + 'calendario/' + this.tipologia + '/' + this.idPadre + '/';
      }
    }, error => {
      this.notificationsService.show('error', '', error.error.message);
      this.loader.spin.next(false);
    });
  }

  onBack() {
    this.componentsService.onBack(this.backUrl);
  }
}
