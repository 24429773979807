import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnagraficaService } from '../anagrafica.service';

@Component({
  selector: 'app-anagrafica-elenco',
  templateUrl: './anagrafica-elenco.component.html',
  styleUrls: ['./anagrafica-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnagraficaElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;
  openVisualizzaDettagli: number = -1;

  constructor(
    private service: AnagraficaService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'denominazione', header: 'Denominazione',    cell: (element: any) => `${element.denominazione}` },
          { columnDef: 'email', header: 'Email',    cell: (element: any) => `${element.email}` },
          { columnDef: 'telefono', header: 'Telefono',    cell: (element: any) => `${element.telefono}` },
        ];
        this.options = {
          linkBase: this.myService.linkBase
        }
      });
  }

  ngOnInit(): void {
  }

  statisticheUtente(idAnagrafica) {
    this.openVisualizzaDettagli = idAnagrafica;
  }

  resetOpenIdModal() {
    this.openVisualizzaDettagli = -1;
  }
}
