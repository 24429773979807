import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { Categoria } from 'src/app/components/elenco/categorie/categoria';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { Elemento } from './elementi/elemento';
import { PrenotazioniService } from '../prenotazioni.service';
import { Prenotazione } from '../prenotazione';

@Component({
  selector: 'app-prenotazioni-scheda',
  templateUrl: './prenotazioni-scheda.component.html',
  styleUrls: ['./prenotazioni-scheda.component.scss']
})
export class PrenotazioniSchedaComponent implements OnInit {
  loading = false;
  arrayCampi: any[any];
  myItem: Prenotazione;
  titoloScheda: string = "Caricamento...";
  arrayCategorie: Categoria[];
  arrayProdotti: any[any];
  modalReference: any;
  backUrl = '';
  idPrenotazione: number = 0;
  arrayPulsanti: any[any];

  constructor(
    private router: Router,
    private mainEngineService: MainEngineService,
    private service: PrenotazioniService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService,
    private buttonService: ButtonService,
    private formComponentsService: FormInputService,
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'nome': 'id', 'required': true},
    };

    this.arrayPulsanti = {
      'confermaPrenotazione' : {'type' : 'custom', 'testo' : 'Conferma prenotazione', 'colorClass' : 'info', 'icon': 'fas fa-check-double', 'actionTooltip' : 'Conferma prenotazione ed invia email al cliente', 'ML' : false, 'mainClass' : 'btn-md'},
      'annullaPrenotazione' : {'type' : 'custom', 'testo' : 'Annulla prenotazione', 'colorClass' : 'warning', 'icon': 'fas fa-ban', 'actionTooltip' : 'Annulla questa prenotazione', 'ML' : false, 'mainClass' : 'btn-md'},
      'ripristinaPrenotazione' : {'type' : 'custom', 'testo' : 'Ripristina prenotazione', 'colorClass' : 'light', 'icon': 'fas fa-arrow-up', 'actionTooltip' : 'Ripristina questa prenotazione', 'ML' : false, 'mainClass' : 'btn-md'},
      'reinviaEmail' : {'type' : 'custom', 'testo' : 'Reinvia email', 'colorClass' : 'light', 'icon': 'fas fa-envelope', 'actionTooltip' : 'Reinvia email al cliente', 'ML' : true, 'mainClass' : 'btn-md'},
    };
  }

  ngOnInit(): void {
    this.backUrl = this.service.linkBase;

    this.route.params.subscribe(
      (params) => {
        this.idPrenotazione = params.id;
        if (this.idPrenotazione != 0) {
          this.formComponentsService.activateLoader(true);
          this.backUrl += '/lastItem/' + this.idPrenotazione;
          this.service.getItem(this.idPrenotazione)
          .subscribe(data => {
            this.myItem = data;
            this.titoloScheda = "Gestione prenotazione # " + this.idPrenotazione;
            this.formComponentsService.activateLoader(false);
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
            this.router.navigate(['/prenotazioni']);
          });
        }
        else {
          this.titoloScheda = "Inserimento nuova prenotazione";
          this.formComponentsService.activateLoader(false);
          this.myItem = new Prenotazione;
        }
    });
  }

  reloadPrenotazione(idPrenotazione: number) {
    if (idPrenotazione != this.idPrenotazione) {
      this.router.navigate(['/prenotazioni/' + idPrenotazione]);
    }
    else {
      this.ngOnInit();
    }
  }


  onConfermaPrenotazione() {
    const that = this;
    const obj = {
      onSuccess(arg) {
        that.loading = true;
        that.service.confermaPrenotazione(that.idPrenotazione)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.componentsService.onBack(that.backUrl);
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenerico(obj, this.idPrenotazione);
  }



  onAnnullaPrenotazione(annulla: boolean = true) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        that.loading = true;
        that.service.annullaPrenotazione(that.idPrenotazione, annulla)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.componentsService.onBack(that.backUrl);
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenerico(obj, this.idPrenotazione);
  }


  onReinviaEmail() {
    const that = this;
    const obj = {
      onSuccess(arg) {
        that.loading = true;
        that.service.reinviaEmail(that.idPrenotazione)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.componentsService.onBack(that.backUrl);
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenerico(obj, this.idPrenotazione);
  }


  onBack() {
    this.componentsService.onBack(this.backUrl);
  }
}
