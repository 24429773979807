import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { ControlloPrenotazioneService } from '../controllo-prenotazione.service';
import { Prenotazione } from '../../prenotazioni/prenotazione';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ButtonService } from 'src/app/components/buttons/button.service';

@Component({
  selector: 'app-op-done',
  templateUrl: './op-done.component.html',
  styleUrls: ['./op-done.component.scss']
})
export class OpDoneComponent implements OnInit {
  idPrenotazione: number;
  securityCode: string;
  myPrenotazione: Prenotazione;
  openVisualizzaIdPrenotazione: number = -1;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formComponentsService: FormInputService,
    private buttonService: ButtonService,
    private notificationsService: NotificationsService,
    private service: ControlloPrenotazioneService,
    private mainEngineService: MainEngineService,

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params) => {
        this.idPrenotazione = params.idPrenotazione;
        this.securityCode = params.securityCode;


          this.formComponentsService.activateLoader(true);
          this.service.getCheckedTickets(this.idPrenotazione, this.securityCode)
          .subscribe(data => {
            this.myPrenotazione = data;
            this.formComponentsService.activateLoader(false);
          },
          (error) => {
            this.mainEngineService.errorHandler(error);
          });
      });
  }


  annullaOperazione() {
    const that = this;

    const obj = {
      onSuccess(arg) {
        that.formComponentsService.activateLoader(true);
        that.service.undoCheckedTickets(that.idPrenotazione, that.securityCode)
        .subscribe(data => {
          that.formComponentsService.activateLoader(false);
          that.notificationsService.show('not_OpOk');
          that.router.navigate(['/controlloPrenotazione/qrcode/' + that.idPrenotazione + '/']);
        },
        (error) => {
          that.mainEngineService.errorHandler(error);
        });
      }
    };

    this.buttonService.confermaGenericoSmart(obj, this.idPrenotazione);
  }

  visualizzaPrenotazione() {
    this.openVisualizzaIdPrenotazione = this.idPrenotazione;
  }

  resetOpenIdPrenotazione(reload: boolean = false) {
    this.openVisualizzaIdPrenotazione = -1;
  }

  nuovoControllo() {
    this.router.navigate(['/controlloPrenotazione/']);
  }

}
