import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { ContenutiService } from 'src/app/service/contenuti.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ImpostazioniService } from './impostazioni.service';

@Component({
  selector: 'app-impostazioni',
  templateUrl: './impostazioni.component.html',
  styleUrls: ['./impostazioni.component.scss']
})
export class ImpostazioniComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: any;
  titoloScheda: string = "Gestione impostazioni";

  constructor(
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private router: Router,
    private componentsService: ComponentsService,
    private notificationsService: NotificationsService,
    private service: ImpostazioniService
  ) {
    this.arrayCampi = {
      'emailRicezionePrenotazioni' : {'id' : 'emailRicezionePrenotazioni', 'nome': 'Indirizzo email per ricezione prenotazioni', 'required': true},
    };
  }

  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);

    this.service.getItem()
    .subscribe(data => {
      this.myItem = data;
      this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

      this.formComponentsService.activateLoader(false);
      this.myForm.patchValue(this.myItem);
      this.myForm.markAsPristine();
    },
    (error) => {
      this.loading = false;
      this.mainEngineService.errorHandler(error);
    });
  }



  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.router.navigate(['/']);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel('/');
  }
}
