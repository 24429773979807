import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GenericItem } from 'src/app/components/class/generic-item';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { MacroCalendario } from '../macro-calendario';

@Component({
  selector: 'app-calendario-scheda',
  templateUrl: './calendario-scheda.component.html',
  styleUrls: ['./calendario-scheda.component.scss']
})
export class CalendarioSchedaComponent implements OnInit {
  @Input() myForm: FormGroup;
  @Input() myItem: MacroCalendario;
  @Input() service: any;
  @Output() formReady: EventEmitter<boolean> = new EventEmitter<boolean>();

  arrayCampi: any[any];
  modifica: boolean = false;
  giorniSettimana: any[];
  tipologiaInserimento: number = 0;
  operazione: number = -1;

  constructor(
    private mainEngineService: MainEngineService,
    private formComponentsService: FormInputService
  ) {
    this.giorniSettimana = [
      {'id': 1, 'denominazione': "Lunedì"},
      {'id': 2, 'denominazione': "Martedì"},
      {'id': 3, 'denominazione': "Mercoledì"},
      {'id': 4, 'denominazione': "Giovedì"},
      {'id': 5, 'denominazione': "Venerdì"},
      {'id': 6, 'denominazione': "Sabato"},
      {'id': 7, 'denominazione': "Domenica"},
    ]

  }

  ngOnInit(): void {
    this.arrayCampi = this.service.arrayCampiCalendario;

    if ((this.myItem != undefined) && (this.myItem.id != undefined)) {
      this.myForm.patchValue(this.myItem);
      this.myForm.markAsPristine();
      this.formReady.emit(true);
    }
    else {
      this.formReady.emit(true);
    }

    this.onClickTipologiaInserimento();
  }


  onClickOperazione() {
    this.operazione = this.myForm.getRawValue().operazione;
    if (this.operazione == 0) {
      // rimuovo tipologiaInserimento
      this.myForm.patchValue({'tipologiaInserimento': ''});
      this.myForm.get('tipologiaInserimento').clearValidators();
      // rimuovo oraInizio
      this.myForm.patchValue({'oraInizio': ''});
      this.myForm.get('oraInizio').clearValidators();
      // rimuovo oraFine
      this.myForm.patchValue({'oraFine': ''});
      this.myForm.get('oraFine').clearValidators();
      // rimuovo orariManuali
      this.myForm.patchValue({'orariManuali': ''});
      this.myForm.get('orariManuali').clearValidators();
    }
    else {
      // rimuovo tipologiaInserimento
      this.myForm.patchValue({'tipologiaInserimento': ''});
      this.myForm.get('tipologiaInserimento').setValidators([Validators.required]);
    }

    this.myForm.get('orariManuali').updateValueAndValidity();
    this.myForm.get('oraInizio').updateValueAndValidity();
    this.myForm.get('oraFine').updateValueAndValidity();
  }


  onClickTipologiaInserimento(reset: boolean = false) {

    if (reset) {
      this.tipologiaInserimento = 0;
      this.myForm.patchValue({'tipologiaInserimento': '0'});
    }
    else {
      this.tipologiaInserimento = this.myForm.getRawValue().tipologiaInserimento;
    }
/*
1: manuale
2: automatico
*/

    // rimuovo oraInizio
    this.myForm.patchValue({'oraInizio': ''});
    this.myForm.get('oraInizio').clearValidators();
    // rimuovo oraFine
    this.myForm.patchValue({'oraFine': ''});
    this.myForm.get('oraFine').clearValidators();
    // rimuovo orariManuali
    this.myForm.patchValue({'orariManuali': ''});
    this.myForm.get('orariManuali').clearValidators();

    switch (this.tipologiaInserimento) {
      case 1: // manuale
        this.myForm.get('orariManuali').setValidators([Validators.required]);
        break;

      case 2: // automatico
        this.myForm.get('oraInizio').setValidators([Validators.required]);
        this.myForm.get('oraFine').setValidators([Validators.required]);
        break;
    }

    this.myForm.get('orariManuali').updateValueAndValidity();
    this.myForm.get('oraInizio').updateValueAndValidity();
    this.myForm.get('oraFine').updateValueAndValidity();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('myItem' in changes) {
      if (Object.keys(this.myItem).length > 0) {
        this.modifica = true;
        this.myForm.patchValue(this.myItem);
        this.myForm.markAsPristine();
        this.formReady.emit(true);
      }
    }
  }



}
