import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Tariffa } from '../tariffa';

@Component({
  selector: 'app-scheda-tariffa',
  templateUrl: './scheda-tariffa.component.html',
  styleUrls: ['./scheda-tariffa.component.scss']
})
export class SchedaTariffaComponent implements OnInit {
  @Input() myForm: FormGroup;
  @Input() myItem: Tariffa;
  @Input() service: any;
  @Output() formReady: EventEmitter<boolean> = new EventEmitter<boolean>();

  arrayCampi: any[any];
  modifica: boolean = false;

  constructor(
    private mainEngineService: MainEngineService,
    private formComponentsService: FormInputService
  ) {
  }

  ngOnInit(): void {
    this.arrayCampi = this.service.arrayCampi;

    if ((this.myItem != undefined) && (this.myItem.id != undefined)) {
      this.myForm.patchValue(this.myItem);
      this.myForm.markAsPristine();
      this.formReady.emit(true);
    }
    else {
      this.formReady.emit(true);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('myItem' in changes) {
      if (Object.keys(this.myItem).length > 0) {
        console.log(this.myItem);
        this.modifica = true;
        this.myForm.patchValue(this.myItem);
        this.myForm.markAsPristine();
        this.formReady.emit(true);
      }
    }
  }
}
