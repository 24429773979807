import { Component, OnInit } from '@angular/core';
import { BiglietteriaService } from '../biglietteria.service';

@Component({
  selector: 'app-biglietteria-calendario',
  templateUrl: './biglietteria-calendario.component.html',
  styleUrls: ['./biglietteria-calendario.component.scss']
})
export class BiglietteriaCalendarioComponent implements OnInit {

  constructor(
    public service: BiglietteriaService
  ) { }

  ngOnInit(): void {
  }

}
