import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Parametri } from '../parametri';

@Component({
  selector: 'app-scheda-parametri',
  templateUrl: './scheda-parametri.component.html',
  styleUrls: ['./scheda-parametri.component.scss']
})
export class SchedaParametriComponent implements OnInit {
  @Input() myForm: FormGroup;
  @Input() myItem: Parametri;
  @Input() service: any;
  @Output() formReady: EventEmitter<boolean> = new EventEmitter<boolean>();

  arrayCampi: any[any];

  constructor(
    private mainEngineService: MainEngineService,
    private formComponentsService: FormInputService
  ) {
  }

  ngOnInit(): void {
    this.arrayCampi = this.service.arrayCampiParametri;

    if ((this.myItem != undefined) && (this.myItem.id != undefined)) {
      this.myForm.patchValue(this.myItem);
      this.myForm.markAsPristine();
      this.formReady.emit(true);
    }
    else {
      this.formReady.emit(true);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('myItem' in changes) {
      if (this.myItem != null) {
        this.myForm.patchValue(this.myItem);
        this.myForm.markAsPristine();
        this.formReady.emit(true);
      }
    }
  }

}
