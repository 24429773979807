import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MetodoPagamento } from 'src/app/pages/metodi-pagamento/metodo-pagamento';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { PrenotazioniService } from '../../prenotazioni.service';


@Component({
  selector: 'app-modal-base-pagamento',
  templateUrl: './modal-base-pagamento.component.html',
  styleUrls: ['./modal-base-pagamento.component.scss']
})
export class ModalBasePagamentoComponent implements OnInit {
  @ViewChild('modal') modal: any;
  @Output() onCancelEmitter = new EventEmitter();
  @Output() onSubmitEmitter = new EventEmitter();

  modalReference: any;
  arrayMetodiPagamento: MetodoPagamento[];
  arrayCampi: any[any];
  myForm: FormGroup;
  itemPagato: boolean = false;
  checkedPagato: boolean = false;
  loading: boolean = false;
  waitModalContent: boolean = true;
  titoloModal: string = "";

  constructor(
    private modalService: NgbModal,
    private service: PrenotazioniService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private buttonService: ButtonService,
    private formComponentsService: FormInputService
  ) { }

  ngOnInit(): void {
  }

  openModal(idPrenotazione: number): void {
    if (idPrenotazione < 0) {
      return;
    }
    this.modalReference = this.modalService.open(this.modal, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});

    this.titoloModal = " - Prenotazione #" + idPrenotazione;

    this.service.getElencoMetodiPagamento()
    .subscribe(data => {
      this.arrayMetodiPagamento = data.items;

      this.arrayCampi = {
        'metodoPagamento' : {'id' : 'metodoPagamento', 'nome': 'Metodo pagamento', 'items': this.arrayMetodiPagamento, 'type': 'RAD', 'required': true, 'inline': false},
        'pagato' : {'id' : 'pagato', 'nome': 'Pagato', 'type': 'BOL'},
        'dataPagamento' : {'id' : 'dataPagamento', 'nome': 'Data pagamento', 'type': 'DTA', 'required': true},
        'inviaEmail' : {'id' : 'inviaEmail', 'nome': 'Invia email di notifica', 'type': 'BOL'},
        'notePagamento' : {'id' : 'notePagamento', 'nome': 'Note ad uso interno', 'type': 'TXA', 'note' : 'Non visibili dal cliente'},
        'id' : {'id' : 'id'},
        'action' : {'id' : 'action'},
      }

      this.myForm = this.formComponentsService.createForm(this.arrayCampi);
      this.myForm.patchValue({ 'metodoPagamento': 0 });
      this.myForm.patchValue({ 'action': 'pagamento' });

      this.service.getItem(idPrenotazione)
      .subscribe(data => {
        data.metodoPagamento = data.metodoPagamento.toString();
        let item = data;
        this.itemPagato = item.pagato;
        this.onClickPagato(item.pagato);
        this.myForm.patchValue(item);
        this.waitModalContent = false;
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });

    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });
  }

  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendPagamento(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.onSubmitEmitter.emit(null);
        this.notificationsService.show('not_OpOk');
        this.onCancel();
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.waitModalContent = true;
    this.loading = false;
    this.onCancelEmitter.emit(null);
    this.modalReference.close();
  }

  onClickPagato(checked) {
    this.checkedPagato = checked;
    if (checked) {
      this.myForm.get('dataPagamento').setValidators([Validators.required]);
    }
    else {
      this.myForm.get('dataPagamento').clearValidators();
    }
    this.myForm.get('dataPagamento').updateValueAndValidity();
  }


  annullaPagamento(idPrenotazione: number) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        this.loading = true;
        that.service.annullaPagamento(idPrenotazione)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.onSubmitEmitter.emit(null);
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenerico(obj, idPrenotazione);
  }
}
