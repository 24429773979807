import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-elenco-tariffe',
  templateUrl: './elenco-tariffe.component.html',
  styleUrls: ['./elenco-tariffe.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ElencoTariffeComponent implements OnInit {
  @Input() service: any;
  @Input() lastId: number = 0;
  @Input() options: any[any];

  columns: any[any];

  constructor() {
    this.columns = [
      { columnDef: 'denominazione', header: 'Denominazione',    cell: (element: any) => `${element.denominazione}` },
      { columnDef: 'prezzo', header: 'Prezzo', currency: 'EUR',    cell: (element: any) => `${element.prezzo}` },
      { columnDef: 'isAttivo', header: 'Attivo',    cell: (element: any) => `${element.isAttivo}` },
      { columnDef: 'proxVariazione', header: 'Prox. variazione',   cell: (element: any) => `${element.proxVariazione}`},
    ];
  }

  ngOnInit(): void {
  }

}
