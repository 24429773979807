import { Anagrafica } from "../anagrafica/anagrafica";
import { ElementoPrenotazione } from "./prenotazioni-scheda/elementi/elemento-prenotazione";

export class Prenotazione {
  id: number;
  idAnagrafica: number;
  _titoloScheda: string;
  metodoPagamento: any;
  pagato: boolean;
  dataPagamento: string;
  notePagamento: string;
  totaleElementi: number;
  numeroElementi: number;
  totaleOrdine: number;
  confermato: number; // opposto di "bozza", usato per condizionare i pulsanti sull'elenco
  completo: boolean; // indica se l'ordine è completo e può essere quindi confermato
  annullato: boolean;
  fail: boolean;
  items: ElementoPrenotazione[];
  anagrafica: Anagrafica
}
