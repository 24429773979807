import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Tariffa } from '../../common-items/tariffa';
import { BiglietteriaService } from '../biglietteria.service';

@Component({
  selector: 'app-biglietteria-scheda-tariffa',
  templateUrl: './biglietteria-scheda-tariffa.component.html',
  styleUrls: ['./biglietteria-scheda-tariffa.component.scss']
})
export class BiglietteriaSchedaTariffaComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  myItem: Tariffa;
  titoloScheda: string = "Caricamento...";
  linkBase: string = "/biglietteria";
  arrayCampi: any[any];

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    public service: BiglietteriaService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = this.service.arrayCampi;
  }

  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.service.arrayCampi);
    this.formComponentsService.activateLoader(true);

    this.route.params.subscribe(
      (params) => {
        if (params.id != '0') {
          this.linkBase += '/lastItem/' + params.id;
          this.service.getItem(params.id)
          .subscribe(data => {
            this.myItem = data;
            this.titoloScheda = "Modifica elemento - '" + this.myItem.denominazione + "'";
            this.formComponentsService.activateLoader(false);
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
          });
        }
        else {
          this.titoloScheda = "Inserimento elemento";
          this.myItem = new Tariffa;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      });
  }


  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.linkBase]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.service.linkBase + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  formReady(ready: boolean) {
    this.formComponentsService.activateLoader(!ready);
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }

  onSave() {
    this.onSubmit(2);
  }

}
