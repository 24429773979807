// https://www.npmjs.com/package/angular2-qrcode

import { Component, Input, OnInit, Output, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { PrenotazioniService } from '../../prenotazioni.service';

@Component({
  selector: 'app-modal-visualizza',
  templateUrl: './modal-visualizza.component.html',
  styleUrls: ['./modal-visualizza.component.scss']
})
export class ModalVisualizzaComponent implements OnInit {
  @ViewChild('modal_visualizzaPrenotazione') modal_visualizzaPrenotazione: any;
  @Input() openIdPrenotazione: number;
  @Output() resetOpenIdPrenotazione = new EventEmitter();

  modalReference: any;
  riassuntoPrenotazione: string = "";
  waitModalContent: boolean = true;
  securityHash: string = "";

  constructor(
    private service: PrenotazioniService,
    private mainEngineService: MainEngineService,
    private modalService: NgbModal,
    private router: Router,
    ) {
  }

  ngOnInit(): void {
  }

  onCancel() {
    this.waitModalContent = true;
    this.resetOpenIdPrenotazione.emit(null);
    this.modalReference.close();
  }

  visualizzaPrenotazione() {
    if (!this.openIdPrenotazione) {
      return;
    }
    this.modalReference = this.modalService.open(this.modal_visualizzaPrenotazione, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});
    this.service.getRiassuntoPrenotazione(this.openIdPrenotazione)
    .subscribe(data => {
      this.riassuntoPrenotazione = data.html;
      this.securityHash = data.securityHash;
      this.waitModalContent = false;
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });
  }


  public ngOnChanges(changes: SimpleChanges): void {
    if ('openIdPrenotazione' in changes) {
      if (this.openIdPrenotazione > -1) {
        this.visualizzaPrenotazione();
      }
    }
  }
}


