import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrenotazioniElencoComponent } from './prenotazioni-elenco/prenotazioni-elenco.component';
import { PrenotazioniSchedaComponent } from './prenotazioni-scheda/prenotazioni-scheda.component';
import { ModalBasePagamentoComponent } from './common/modal-base-pagamento/modal-base-pagamento.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { CommonItemsModule } from '../common-items/common-items.module';
import { ModalVisualizzaComponent } from './prenotazioni-elenco/modal-visualizza/modal-visualizza.component';
import { ModalGestionePagamentoComponent } from './prenotazioni-elenco/modal-gestione-pagamento/modal-gestione-pagamento.component';
import { ModalAnagraficaComponent } from './prenotazioni-scheda/anagrafica/modal-anagrafica/modal-anagrafica.component';
import { TabAnagraficaComponent } from './prenotazioni-scheda/anagrafica/tab-anagrafica/tab-anagrafica.component';
import { ModalAggiungiElementoComponent } from './prenotazioni-scheda/elementi/modal-aggiungi-elemento/modal-aggiungi-elemento.component';
import { TabElencoElementiComponent } from './prenotazioni-scheda/elementi/tab-elenco-elementi/tab-elenco-elementi.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalPagamentoComponent } from './prenotazioni-scheda/pagamento/modal-pagamento/modal-pagamento.component';
import { TabPagamentoComponent } from './prenotazioni-scheda/pagamento/tab-pagamento/tab-pagamento.component';
import { AnagraficaModule } from '../anagrafica/anagrafica.module';
import { ModalRimuoviElementoComponent } from './prenotazioni-scheda/elementi/modal-rimuovi-elemento/modal-rimuovi-elemento.component';

@NgModule({
  declarations: [
    PrenotazioniElencoComponent,
    PrenotazioniSchedaComponent,
    ModalBasePagamentoComponent,
    ModalVisualizzaComponent,
    ModalGestionePagamentoComponent,
    ModalAnagraficaComponent,
    TabAnagraficaComponent,
    ModalAggiungiElementoComponent,
    TabElencoElementiComponent,
    ModalPagamentoComponent,
    TabPagamentoComponent,
    ModalRimuoviElementoComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NgbModule,
    CommonItemsModule,
    AnagraficaModule,
  ],
  exports: [
    ModalVisualizzaComponent
  ]
})
export class PrenotazioniModule { }
