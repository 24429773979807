import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElencoTariffeComponent } from './elenco-tariffe/elenco-tariffe.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { SchedaTariffaComponent } from './scheda-tariffa/scheda-tariffa.component';
import { SchedaParametriComponent } from './scheda-parametri/scheda-parametri.component';
import { PrezziTariffaComponent } from './prezzi-tariffa/prezzi-tariffa.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableModule } from '@angular/material/table';
import { CalendarioComponent } from './calendario/calendario.component';
import { CalendarioSchedaComponent } from './calendario-scheda/calendario-scheda.component';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin
]);

@NgModule({
  declarations: [
    ElencoTariffeComponent,
    SchedaTariffaComponent,
    SchedaParametriComponent,
    PrezziTariffaComponent,
    CalendarioComponent,
    CalendarioSchedaComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NgbModule,
    MatTableModule,
    FullCalendarModule
  ],
  exports: [
    ElencoTariffeComponent,
    SchedaTariffaComponent,
    SchedaParametriComponent,
    PrezziTariffaComponent,
    CalendarioComponent,
    CalendarioSchedaComponent
  ],
})
export class CommonItemsModule { }
