import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-controllo',
  templateUrl: './main-controllo.component.html',
  styleUrls: ['./main-controllo.component.scss']
})
export class MainControlloComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  onClickScanQrcode() {
    this.router.navigate(['/controlloPrenotazione/qrcode']);
  }

  onClickRicerca() {
    this.router.navigate(['/controlloPrenotazione/ricerca']);
  }

}
