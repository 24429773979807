import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { BiglietteriaService } from 'src/app/pages/biglietteria/biglietteria.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { PrenotazioniService } from '../../../prenotazioni.service';
import { ElementiService } from '../elementi.service';
import { Elemento } from '../elemento';

@Component({
  selector: 'app-modal-aggiungi-elemento',
  templateUrl: './modal-aggiungi-elemento.component.html',
  styleUrls: ['./modal-aggiungi-elemento.component.scss']
})
export class ModalAggiungiElementoComponent implements OnInit {
  @ViewChild('modal') modal: any;
  @Input() idPrenotazione: number = 0;
  @Input() openModalElemento: number = 0;
  @Output() onSubmitEmitter = new EventEmitter<number>();
  @Output() onCancelEmitter = new EventEmitter<void>();
/* openModalElemento sarà sempre 0 in quanto non esiste modifica di elementi della prenotazione. Al momento dell'inserimento creo un entry su prenotazione_elementi per ogni singolo elemento (in quanto ogni biglietto deve avere il suo)
*/
  arrayCampi: any[any];
  arrayCampiTariffe: any[any] = {};
  arrayTariffe: any[any];
  arrayOrari: any[any];
  arrayPulsanti: any[any];
  arrayElementi: any[any];
  arrayTipologieElementi: any[any];
  myForm: FormGroup;
  myFormIniziale: FormGroup;
  mostraTariffe: boolean = false;
  loading = false;
  modalReference: any;
  waitModalContent: boolean = true;
  elementoPrenotazione: Elemento
  modalTitle: string;

  constructor(
    private elementiService: BiglietteriaService,
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private service: ElementiService,
    private modalService: NgbModal,
    private componentsService: ComponentsService,
    private prenotazioniService: PrenotazioniService
  ) {
    this.arrayCampi = {
      'id' : {'id' : 'id', 'type': 'NUM'},
      'idPrenotazione' : {'id' : 'idPrenotazione', 'type': 'NUM'},
      'action' : {'id' : 'action'},
      'tipologiaElemento' : {'id' : 'tipologiaElemento', 'nome': 'Tipologia', 'required': true, 'type': 'TXT'},
      'idElemento' : {'id' : 'idElemento', 'nome': 'Elemento', 'required': true, 'type': 'NUM'},
      'orario' : {'id' : 'orario', 'nome': 'Orario', 'required': true, 'type': 'TXT'},
      'totTariffe' : {'id' : 'totTariffe', 'type': 'NUM', 'defValue': 0},
      'data' : {'id' : 'data', 'nome': 'Data', 'type': 'DTA', 'minToday': true, 'required': true},
    };

    this.arrayPulsanti = {
      'mostraTariffe' : {'type' : 'custom', 'testo' : 'Mostra tariffe', 'colorClass' : 'primary', 'icon': 'bx bx-money', 'actionTooltip' : 'Mostra le tariffe previste per la data selezionata'},
      'modificaRicerca' : {'type' : 'custom', 'testo' : 'Modifica ricerca', 'colorClass' : 'warning', 'icon': 'bx bx-search-alt-2', 'actionTooltip' : 'Modifica la ricerca iniziale'},
    };

  }

  ngOnInit(): void {
    if (!this.idPrenotazione) {
      this.idPrenotazione = 0;
    }
  }


  openModal() {
    this.modalReference = this.modalService.open(this.modal, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.myForm.patchValue({ 'id': this.openModalElemento });
    this.myForm.patchValue({ 'idPrenotazione': this.idPrenotazione });
    this.myForm.patchValue({ 'action': 'aggiungiElemento' });

    this.prenotazioniService.getTipologieElementi()
    .subscribe(data => {
      this.arrayTipologieElementi = data.items;
      if (this.openModalElemento == 0) {
        this.modalTitle = "Aggiunta elemento";
        this.myForm.patchValue({ 'tipologiaElemento': 'B' });
        this.myForm.patchValue({ 'idElemento': 0 });
        this.waitModalContent = false;
      }
      else {

      }
      this.myForm.get('orario').clearValidators();
      this.myForm.get('orario').updateValueAndValidity();
      this.myFormIniziale = this.myForm;
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });
  }


  modificaRicerca() {
    this.mostraTariffe = false;
    this.myForm = this.myFormIniziale;

    this.myForm.get('orario').clearValidators();
    this.myForm.get('orario').updateValueAndValidity();
    this.myForm.patchValue({ 'totTariffe': 0 });

    this.arrayOrari = null;
    this.arrayTariffe = null;
    this.arrayCampiTariffe = new Array();
  }


  onTipologiaSelezionata() {
    // TBD da fare quando metto le visite guidate
  }


  mostraTariffeElemento() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.waitModalContent = true;

    this.prenotazioniService.getElencoTariffeElemento(this.myForm.getRawValue())
    .subscribe(data => {
      for (let key in data.orari) {
        data.orari[key].denominazione = data.orari[key].denominazione + " - Capienza: " + data.orari[key].capienza + " - Residuo: " + data.orari[key].residuo;
      }
      this.arrayOrari = data.orari;

      for (let key in data.items) {
        this.arrayCampiTariffe['tariffa_' + data.items[key].id] = {'id' : 'tariffa_' + data.items[key].id,
                                                          'nome': data.items[key].denominazione,
                                                          'note': data.items[key].HTML_descrizione,
                                                          'type': 'NUM'};
      }
      this.myForm.patchValue({ 'totTariffe': data.items.length });
      this.myForm = this.formComponentsService.createForm(this.arrayCampiTariffe, this.myForm);
      this.arrayCampi = {...this.arrayCampi, ...this.arrayCampiTariffe};

      this.myForm.get('orario').setValidators([Validators.required]);
      this.myForm.get('orario').updateValueAndValidity();

      this.arrayTariffe = data.items;
      this.mostraTariffe = true;
      this.waitModalContent = false;
    },
    (error) => {
      this.waitModalContent = true;
      this.mainEngineService.errorHandler(error);
    });
  }

  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    let somma = 0;
    for (let key in this.arrayCampiTariffe) {
      if (this.myForm.get(key).value != '') {
        somma += parseInt(this.myForm.get(key).value);
      }
    }

    if (!somma) {
      this.notificationsService.show('error', 'Errore: è necessario selezionare almeno una tipologia di tariffa');
      return;
    }



    this.loading = true;

    this.service.sendElementoPrenotazione(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (idPrenotazione) => {
        this.notificationsService.show('not_OpOk');
        this.waitModalContent = true;
        this.loading = false;
        this.modalReference.close();
        this.onSubmitEmitter.emit(idPrenotazione);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.waitModalContent = true;
    this.onCancelEmitter.emit(null);
    this.modalReference.close();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('openModalElemento' in changes) {
      if (this.openModalElemento >= 0) {
        this.openModal();
      }
    }
  }


}
