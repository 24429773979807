import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { MacroCalendario } from '../common-items/macro-calendario';
import { Parametri } from '../common-items/parametri';
import { ParametriCalendario } from '../common-items/parametri-calendario';
import { StatoTariffa } from '../common-items/stato-tariffa';
import { Tariffa } from '../common-items/tariffa';
import { VariazioneTariffa } from '../common-items/variazione-tariffa';

@Injectable({
  providedIn: 'root'
})
export class BiglietteriaService {
  apiUrl: string;
  linkBase: string = "biglietteria";
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;
  arrayCampi: any[any];
  arrayCampiParametri: any[any];
  arrayCampiCalendario: any[any];

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + '/biglietteria/';
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'denominazione' : {'id' : 'denominazione', 'nome': 'Denominazione', 'required': true},
      'descrizione' : {'id' : 'descrizione', 'nome': 'Descrizione', 'type': 'TXA'},
      'prezzo' : {'id' : 'prezzo', 'nome': 'Prezzo iniziale', 'type': 'VLT', 'required': true},
      'attivo' : {'id' : 'attivo', 'nome': 'Attivo subito', 'type': 'CHK', 'required': false},
    };

    this.arrayCampiParametri = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'capienza' : {'id' : 'capienza', 'nome': 'Capienza massima', 'required': true, 'note': 'Indica la quantità di default di titoli di ingresso vendibili per ogni singola fascia (indipendentemente dalla tipologia del titolo di ingresso)'},
      'durata' : {'id' : 'durata', 'nome': 'Durata', 'type': 'TIME', 'required': true, 'note': 'Indica la durata di default di ogni fascia che viene creata'},
    };

    this.arrayCampiCalendario = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'operazione' : {'id' : 'operazione', 'nome': 'Tipologia', 'items': {1: 'Inserisci (o aggiorna) fasce', 0:'Cancella fasce esistenti'}, 'type': 'RAD', 'required': true, 'note': 'Nel caso di inserimento nuove fasce, eventuali fasce esistenti con stessa data ed orario verranno sostituite dai nuovi valori','inline' : true},
      'periodo' : {'id' : 'periodo', 'nome': 'Periodo', 'type': 'PER', 'minToday': true, 'required': true},
      'giorni' : {'id' : 'giorni', 'nome': 'Giorni della settimana', 'type': 'SLM', 'multiple': true, 'required': true},
      'tipologiaInserimento' : {'id' : 'tipologiaInserimento', 'nome': 'Metodo di inserimento', 'items': {1: 'Inserimento manuale degli orari', 2:'Creazione automatica degli orari'}, 'type': 'RAD', 'required': true, 'note': 'Nel caso di ripetizioni continue di orari, utilizzare la creazione automatica; se invece gli orari di inizio non seguono una regola, utilizzare inserimento manuale','inline' : true},
      'oraInizio' : {'id' : 'oraInizio', 'nome': 'Ora inizio prima fascia', 'type': 'ORA', 'required': true, 'note': 'Orario di inizio della prima fascia generata. Le successive inizieranno al termine delle precedenti, in base alla durata della fascia'},
      'oraFine' : {'id' : 'oraFine', 'nome': 'Ora fine ultima fascia', 'type': 'ORA', 'required': true, 'note': 'Massimo orario di fine dell\'ultima fascia generata (l\'ultima fascia potrenne non finire esattamente a questo orario, dipende dalla durata delle fasce) '},
      'oraInizioCancellazione' : {'id' : 'oraInizioCancellazione', 'nome': 'Ora inizio prima fascia', 'type': 'ORA', 'required': false, 'note': 'Se non indicato, verranno cancellate tutte le fasce con orario di inizio previsto a partire dalle ore 00:00'},
      'oraFineCancellazione' : {'id' : 'oraFineCancellazione', 'nome': 'Ora fine ultima fascia', 'type': 'ORA', 'required': false, 'note': 'Se non indicato, verranno cancellate tutte le fasce con orario di inizio previsto fino alle ore 23:59 '},
      'durata' : {'id' : 'durata', 'nome': 'Durata fascia', 'type': 'ORA', 'required': true, 'note': 'Durata delle fasce generate. Ogni fascia successiva partirà al termine della precedente'},
      'capienza' : {'id' : 'capienza', 'nome': 'Capienza massima', 'required': true, 'note': 'Indica la quantità massima di titoli di ingresso vendibili per ogni singola fascia (indipendentemente dalla tipologia del titolo di ingresso)'},
      'orariManuali' : {'id' : 'orariManuali', 'nome': 'Orari di inizio', 'required': true, 'note': 'Inserire gli orari di inizio delle fasce in formato \'hh:mm\', separati da \',\' (senza inserire spazi) '},
    };

  }

  getElenco(): Observable<Tariffa[]> {
    return this.http.get<Tariffa[]>(this.apiUrl + "?op=elencoTariffe")
  }

  getElencoVariazioni(idTariffa: number): Observable<VariazioneTariffa[]> {
    return this.http.get<VariazioneTariffa[]>(this.apiUrl + "?op=elencoVariazioni&idTariffa=" + idTariffa)
  }

  getStatoTariffa(idTariffa: number): Observable<StatoTariffa[]> {
    return this.http.get<StatoTariffa[]>(this.apiUrl + "?op=andamentoTariffa&idTariffa=" + idTariffa)
  }

  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + 'op/opzioni')
  }

  getParametri(): Observable<Parametri> {
    return this.http.get<Parametri>(this.apiUrl + 'op/parametri')
  }

  getItem(id: number): Observable<Tariffa> {
    return this.http.get<Tariffa>(this.apiUrl + id)
  }

  getVariazione(idVariazione: number): Observable<VariazioneTariffa> {
    return this.http.get<VariazioneTariffa>(this.apiUrl + "?op=variazioneTariffa&id=" + idVariazione)
  }

  getMacroCalendario(id: number): Observable<MacroCalendario> {
    return this.http.get<MacroCalendario>(this.apiUrl + "?op=macroCalendario&id=" + id)
  }

  getParametriCalendario(): Observable<ParametriCalendario> {
    return this.http.get<ParametriCalendario>(this.apiUrl + "?op=parametriCalendario" )
  }

  deleteVariazione(idVariazione: number) {
    return this.http.delete<VariazioneTariffa>(this.apiUrl + "?op=variazioneTariffa&id=" + idVariazione)
  }

  toggleAttivo(id: number) {
    const tipologia = 'attivo';
    return this.http.patch<any>(this.apiUrl + id, {tipologia})
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }


  deleteItem(idTariffa: number) {
    return this.http.delete(this.apiUrl + "?op=tariffa&id=" + idTariffa)
  }

  sendVariazione(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl + 'op/variazione' , body);
  }

  sendParametri(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl + 'op/parametri' , body);
  }


  sendMacroCalendario(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl + 'op/macroCalendario' , body);
  }
}
