import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Prenotazione } from '../../../prenotazione';
import { PrenotazioniService } from '../../../prenotazioni.service';
import { ModalPagamentoComponent } from '../modal-pagamento/modal-pagamento.component';

@Component({
  selector: 'app-tab-pagamento',
  templateUrl: './tab-pagamento.component.html',
  styleUrls: ['./tab-pagamento.component.scss']
})
export class TabPagamentoComponent implements OnInit {
  @Input() prenotazione: Prenotazione;
  @Output() reloadPrenotazione = new EventEmitter<number>();
  @ViewChild(ModalPagamentoComponent, {static : true}) child:ModalPagamentoComponent;

  arrayPulsanti: any[any];
  statoPagamento: string;
  openModalId: number = -1;

  constructor(
    private mainEngineService: MainEngineService,
    private service: PrenotazioniService,
  ) {
    this.arrayPulsanti = {
      'modifica' : {'type' : 'edit'},
      'annulla' : {'type' : 'annulla', 'testo' : 'Annulla pagamento', 'actionTooltip' : 'Annulla la registrazione del pagamento', 'ML' : true},
    };
  }

  ngOnInit(): void {
    if (this.prenotazione && this.prenotazione.id) {
      this.service.getPagamento(this.prenotazione.id)
      .subscribe(data => {
        this.statoPagamento = data;
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });
    }
  }

  modalForm() {
    this.openModalId = this.prenotazione.id;
  }


  onSubmit() {
    this.onCancel();
    this.ngOnInit();
    this.reloadPrenotazione.emit(this.prenotazione.id);
  }

  onCancel() {
    this.openModalId = -1;
  }


  resetOpenModal() {
    this.openModalId = -1;
  }


  annullaPagamento() {
    this.child.annullaPagamento(this.prenotazione.id);
  }

}
