import { MenuItem } from "src/app/components/modules/menu.model";

export const MENU: MenuItem[] = [
/*
    {
      label: 'Dashboard',
      icon: 'fas fa-home',
      link: '/',
      strict: true
    },
*/
    {
      label: 'Prenotazioni',
      icon: 'fas fa-ticket-alt',
      link: '/prenotazioni',
    },
    {
      label: 'Biglietteria',
      icon: 'fas fa-ticket-alt',
      link: '/biglietteria',
    },
    {
      label: 'Anagrafica',
      icon: 'fas fa-users',
      link: '/anagrafica',
    },

    {
      label: 'Controllo prenotazione',
      icon: 'fas fa-clipboard-check',
      link: '/controlloPrenotazione',
    },
    {
      label: 'CONFIGURAZIONE',
      isTitle: true
    },
    {
      label: 'Metodi di pagamento',
      icon: 'far fa-credit-card',
      link: '/metodiPagamento',
    },
    {
      label: 'Impostazioni',
      icon: 'fas fa-cogs',
      link: '/impostazioni',
    },
    {
      label: 'GUIDE',
      isTitle: true
    },
    {
      label: 'Video tutorial',
      icon: 'fab fa-youtube',
      extLink: 'https://www.youtube.com/playlist?list=PLp34j2WM5JT4oF44dcUbUAPfhnMh4MdvY',
    },

];
