import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GenericItem } from 'src/app/components/class/generic-item';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Anagrafica } from '../anagrafica';
import { AnagraficaService } from '../anagrafica.service';

@Component({
  selector: 'app-anagrafica-form',
  templateUrl: './anagrafica-form.component.html',
  styleUrls: ['./anagrafica-form.component.scss']
})
export class AnagraficaFormComponent implements OnInit {
  @Input() myForm: FormGroup;
  @Input() myItem: Anagrafica;
  @Input() fromPrenotazione: number = -1;
  @Output() formReady: EventEmitter<boolean> = new EventEmitter<boolean>();

  arrayCampi: any[any];
  arrayStati: GenericItem[];

  constructor(
    private mainEngineService: MainEngineService,
    private service: AnagraficaService,
    private formComponentsService: FormInputService
  ) {
    this.arrayCampi = this.service.arrayCampi;
    this.formComponentsService.inputClass = this.formComponentsService.fullInputClass;
    this.formComponentsService.noteClass = this.formComponentsService.fullNoteClass;
  }

  ngOnInit(): void {
    let that = this;
    this.mainEngineService.getElencoStati()
    .subscribe(data => {
      this.arrayStati = data;
      if ((this.myItem != undefined) && (this.myItem.id != undefined)) {
        setTimeout(function(){
          that.myForm.patchValue(that.myItem);
          that.myForm.patchValue(that.myItem);
          that.myForm.markAsPristine();
          that.formReady.emit(true);
        }, 200);
      }
      else {
        that.formReady.emit(true);
      }
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('myItem' in changes) {
      if (this.myItem != null) {
        this.myForm.patchValue(this.myItem);
        this.myForm.markAsPristine();
        this.formReady.emit(true);
      }
    }
  }


}
