import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainControlloComponent } from './main-controllo/main-controllo.component';
import { ScansionaQrcodeComponent } from './scansiona-qrcode/scansiona-qrcode.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonItemsModule } from '../common-items/common-items.module';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { CheckFailComponent } from './check-fail/check-fail.component';
import { CheckSuccessComponent } from './check-success/check-success.component';
import { PrenotazioniModule } from '../prenotazioni/prenotazioni.module';
import { OpDoneComponent } from './op-done/op-done.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RicercaComponent } from './ricerca/ricerca.component';


@NgModule({
  declarations: [
    MainControlloComponent,
    ScansionaQrcodeComponent,
    CheckFailComponent,
    CheckSuccessComponent,
    OpDoneComponent,
    RicercaComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NgbModule,
    CommonItemsModule,
    NgxScannerQrcodeModule,
    PrenotazioniModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ControlloPrenotazioneModule { }
